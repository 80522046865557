import { Language } from 'types/language'

export default {
  'Intro Text': {
    [Language.EN]: 'An Interactive Map App Exposing Climate Change, Environmental Racism & Historical Erasure On The Brooklyn Waterfront',
    [Language.ES]: 'una aplicación de mapas interactivos que expone el cambio climático, el racismo ambiental y el borrado histórico en el litoral de Brooklyn'
  },
  'Enter App': {
    [Language.EN]: 'Enter App',
    [Language.ES]: 'Entrar al App'
  },
  'Explore Map': {
    [Language.EN]: 'Explore Map',
    [Language.ES]: 'Explorar Mapa'
  },
  'Contributors': {
    [Language.EN]: 'CONTRIBUTORS',
    [Language.ES]: 'COLABORADORES'
  },
  'Citation': {
    [Language.EN]: 'CITATION',
    [Language.ES]: 'CITA'
  },
  'Image Citation': {
    [Language.EN]: 'IMAGE CITATION',
    [Language.ES]: 'IMAGE CITATION'
  },
  'Map Key': {
    [Language.EN]: 'MAP KEY',
    [Language.ES]: 'CLAVE DEL MAPA'
  },
  'ON/OFF': {
    [Language.EN]: 'ON/OFF',
    [Language.ES]: 'ENCENDER/APAGAR'
  },
  'Climate Timeline': {
    [Language.EN]: 'Climate Timeline',
    [Language.ES]: 'Cronología Ambiental'
  },
  'Disclaimer': {
    [Language.EN]: "Hi there!\n\nFinal Notice is new, in-progress, and imperfect. We welcome any feedback or suggestions at [info@finalnoticebk.com](mailto:info@finalnoticebk.com). \n\nWe’re also raising money to make our app more accessible; you can donate [here](https://www.finalnoticebk.com/fundraising-goal). \n\nHappy exploring.",
    [Language.ES]: "¡Hola!\n\nFinal Notice es nuevo, está en proceso, y es imperfecto. Aceptamos cualquier comentario o sugerencia a [info@finalnoticebk.com](mailto:info@finalnoticebk.com).\n\nTambién estamos levantando fondos para hacer nuestra aplicación más accesible; puede dejar sus donaciones [aquí](https://www.finalnoticebk.com/fundraising-goal).\n\n¡Disfrute de su exploración!"
  },
  'Menu': {
    [Language.EN]: 'Menu',
    [Language.ES]: 'Menú'
  }
} as Record<string, Record<Language, string>>
