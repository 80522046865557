import React, { Component } from 'react'
import { ScrollView, View, Text, Dimensions } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import { tailwind, getColor } from 'styles/tailwind'
import { StyledText, StyledMarkdown } from 'components/StyledText'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { data } from 'db'
import { Language } from 'types/language'
import translations from 'content/translations'

export class AppendixAccordian extends Component<{ language: Language }> {
  state = {
    activeSections: []
  }

  _renderSectionTitle = (section, _, isActive) => {
    return <View style={tailwind(`border-solid border-b-2 border-gray-200`)} />
  }

  _renderHeader = (section, _, isActive) => {
    return (
      <View
        style={tailwind(
          'flex flex-row justify-between w-full items-center pl-10 pr-4 py-2'
        )}>
        <StyledText
          el="p"
          style={{
            height: 'auto',
            textTransform: 'uppercase'
          }}>
          {this.props.language === Language.EN
            ? section.title_ENG
            : section.title_SPA}
        </StyledText>
        <Icon
          name={isActive ? 'chevron-up' : 'chevron-down'}
          color={'#000'}
          size={24}></Icon>
      </View>
    )
  }

  _renderContent = (section, _, isActive) => {
    return (
      <View
        style={{
          ...tailwind('pl-10 mr-4 pb-4 pr-2')
        }}>
        <StyledMarkdown>
          {this.props.language === Language.EN
            ? section.description_ENG
            : section.description_SPA}
        </StyledMarkdown>
        <StyledMarkdown>
          {`\n###### ${translations.Contributors[this.props.language]}\n` +
            section.contributors}
        </StyledMarkdown>
        <StyledMarkdown>
          {`\n###### ${translations.Citation[this.props.language]}\n` +
            section.source_markdown || ''}
        </StyledMarkdown>
      </View>
    )
  }

  _updateSections = (activeSections) => {
    this.setState({ activeSections })
  }

  render() {
    const appendixItems = data.appendix.filter((item) => {
      return (
        (this.props.language === Language.EN &&
          item.title_ENG?.length > 0 &&
          item.description_ENG?.length > 0) ||
        (this.props.language === Language.ES &&
          item.title_SPA?.length > 0 &&
          item.description_SPA?.length > 0)
      )
    })

    return (
      <Accordion
        sections={appendixItems}
        activeSections={this.state.activeSections}
        renderSectionTitle={this._renderSectionTitle}
        renderHeader={this._renderHeader}
        renderContent={this._renderContent}
        onChange={this._updateSections}
        underlayColor={'rgba(0,0,0,0)'}
      />
    )
  }
}
