export default [
  {
    "slug": "bkpl",
    "url": "https://www.bklynlibrary.org/locations/center-for-brooklyn-history"
  },
  {
    "slug": "blue-city",
    "url": "https://www.bluecityredhook.com/"
  },
  {
    "slug": "bac",
    "url": "https://www.brooklynartscouncil.org/"
  },
  {
    "slug": "dance-nyc",
    "url": "https://www.dance.nyc/"
  },
  {
    "slug": "drpp",
    "url": "http://www.drpp.nyc/"
  },
  {
    "slug": "elp",
    "url": "https://elpuente.us/"
  },
  {
    "slug": "reti-center",
    "url": "https://www.reticenter.org/"
  },
  {
    "slug": "rhf",
    "url": "http://www.added-value.org/"
  },
  {
    "slug": "rhi",
    "url": "https://rhicenter.org/"
  },
  {
    "slug": "rrh",
    "url": "https://www.resilientredhook.org/"
  },
  {
    "slug": "ttt",
    "url": "https://pages.devex.com/turningthetide.html"
  },
  {
    "slug": "seven",
    "url": "http://www.lukeohlson.com/7cinema-1"
  },
  {
    "slug": "revel",
    "url": "https://gorevel.com/"
  },
  {
    "slug": "rethink",
    "url": "https://www.rethink-films.com/"
  },
  {
    "slug": "portside",
    "url": "https://portsidenewyork.org/"
  },
  {
    "slug": "obf",
    "url": "https://onebrooklynfund.org/"
  },
  {
    "slug": "ny-choral",
    "url": "https://nychoral.org/"
  },
  {
    "slug": "not",
    "url": "https://www.notaligne.com/"
  },
  {
    "slug": "nca",
    "url": "http://www.newtowncreekalliance.org/"
  },
  {
    "slug": "living",
    "url": "https://www.livingasaleader.com/"
  },
  {
    "slug": "leimay",
    "url": "https://www.instagram.com/leimayfoundation"
  },
  {
    "slug": "katie",
    "url": "https://katiedeandesigns.com/"
  },
  {
    "slug": "harkness",
    "url": "https://harknessfoundation.org/"
  },
  {
    "slug": "gbx",
    "url": "https://www.facebook.com/Gowanus-Bay-Terminal-GBX-170027240017555/"
  },
  {
    "slug": "bbp",
    "url": "https://www.brooklyn-usa.org/"
  },
  {
    "slug": "whitney",
    "url": "https://whitneybrowne.com/"
  }
]

