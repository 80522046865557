import React, { useState } from 'react'
import tailwind from 'tailwind-rn'
import { CategoryIcon } from './CategoryIcon'
import { Site } from 'types/site'
import _ from 'lodash'
import { useLayer, Arrow } from 'react-laag'
import { StyledText } from './StyledText'
import { NavigationContainer } from '@react-navigation/native'

export default function MapMarker({
  lat,
  lng,
  site,
  onClick
}: {
  lat: number
  lng: number
  site: Site
  onClick: (site: Site) => void
}) {
  const [isOpen, setOpen] = useState<boolean>(false)

  // https://github.com/google-map-react/google-map-react/issues/704
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 8,
    containerOffset: 8,
    arrowOffset: 16,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
    onDisappear: (disappearType) => {
      if (disappearType === 'full') {
        setOpen(false)
      }
    },
    possiblePlacements: [
      'bottom-start',
      'bottom-end',
      'bottom-center',
      'top-start',
      'top-center',
      'top-end'
    ],
    snap: true
  })

  function handleClick(value) {
    setOpen(false)
    onClick(value)
  }

  return (
    <>
      <div
        {...triggerProps}
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: 30,
          width: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'none',
          zIndex: isOpen ? 10 : undefined
        }}
        onClick={() => setOpen(!isOpen)}>
        <CategoryIcon categories={site.categories} variant="map" />
      </div>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              ...tailwind('py-2 px-4 bg-white rounded-full text-center'),
              boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.2)',
              maxWidth: '75vw',
              zIndex: 11,
              cursor: 'pointer'
            }}
            onClick={() => handleClick(site)}>
            <StyledText>{site.title + '\u00a0\u00a0›'}</StyledText>
            <Arrow size={8} backgroundColor={'#fff'} {...arrowProps} />
          </div>
        )}
    </>
  )
}
