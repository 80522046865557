import React, { useContext, useMemo, useState, useEffect } from 'react'
import {
  View,
  Modal,
  TouchableOpacity,
  ScrollView,
  Dimensions
} from 'react-native'
import { MapScreenProps } from 'types/Navigation'
import { MapCanvas } from 'components/MapCanvas'
import { MapDrawer } from 'components/MapDrawer'
import tailwind from 'tailwind-rn'
import { filterSitesByCategories } from 'providers/SitesProvider'
import _ from 'lodash'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { StyledMarkdown } from 'components/StyledText'
import { AppContext } from 'appState'
import { data } from 'db'
import { Language } from 'types/language'
import { TitleBar } from 'components/TitleBar'

const Map = ({ navigation }: MapScreenProps) => {
  const app = useContext(AppContext)

  const [filtersMenuVisible, setFiltersMenuVisible] = useState<boolean>(true)

  const [visibleLayers, setVisibleLayers] = useState<string[]>(
    app.state.layers.active
  )

  const [loading, setLoading] = useState<string>(app.state.modal.loading.get)

  const [baseLayerMenuVisible, setBaseLayerMenuVisible] =
    useState<boolean>(false)

  const visibleSites = useMemo(
    () => filterSitesByCategories(app.state.allSites, visibleLayers),
    [visibleLayers]
  )

  useEffect(() => {
    app.state.modal.loading.set(loading)
  }, [loading])

  const handleToggle = (layer: string) => {
    let a = Array.from(visibleLayers)
    var i = a.indexOf(layer)
    if (i === -1) {
      a.push(layer)
    } else {
      a.splice(i, 1)
    }
    setVisibleLayers(a)
  }

  const handleOpenSite = (site) => {
    navigation.navigate('SiteModal', {
      site: site,
      language: app.state.language.get
    })
  }

  const baseLayerDetails = data.overlayLayers.find(
    (l: any) => l.slug === app.state.baseLayer.get
  )

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width,
        overflow: 'hidden'
      }}>
      <MapCanvas
        sites={visibleSites}
        onClickSite={handleOpenSite}
        baseLayer={app.state.baseLayer.get}
      />
      <MapDrawer
        filterLayersMenu={filtersMenuVisible}
        setFilterLayersMenu={setFiltersMenuVisible}
        activeLayers={visibleLayers}
        baseLayerMenu={baseLayerMenuVisible}
        setBaseLayerMenu={setBaseLayerMenuVisible}
        baseLayer={app.state.baseLayer.get}
        setBaseLayer={app.state.baseLayer.set}
        toggleLayer={handleToggle}
        language={app.state.language.get}
      />
      <TitleBar
        callback={() => navigation.navigate('Home')}
        language={app.state.language.get}
      />
      {baseLayerDetails && app.state.baseLayer.modal.visible.get && (
        <View>
          <Modal
            animationType="slide"
            transparent={true}
            visible={app.state.baseLayer.modal.visible.get}>
            <View
              style={tailwind(
                'mt-48 p-4 pt-16 bg-white rounded-t-lg mb-0 pb-0 overflow-hidden flex'
              )}>
              <StyledMarkdown>
                {app.state.language.get === Language.EN
                  ? baseLayerDetails.description_en
                  : baseLayerDetails.description_sp}
              </StyledMarkdown>
            </View>
            <TouchableOpacity
              style={{
                ...tailwind(
                  'absolute mt-48 right-0 w-2/12 flex-shrink-0 flex flex-row items-center justify-end rounded-l-full bg-black h-12 w-12 z-20'
                ),
                top: 8
              }}
              onPress={() => {
                setFiltersMenuVisible(false)
                app.state.baseLayer.modal.visible.set(false)
              }}>
              <Icon
                style={tailwind('p-4')}
                name="close"
                size={16}
                color="#fff"
              />
            </TouchableOpacity>
          </Modal>
        </View>
      )}
    </View>
  )
}

export default Map
