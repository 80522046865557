import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  SafeAreaView,
  ScrollView,
  Platform,
  Linking
} from 'react-native'
import { tailwind } from 'styles/tailwind'
import { StyledMarkdown, StyledText } from 'components/StyledText'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { MenuPageScreenProps } from 'types/Navigation'
import { drpp as getDrpp } from 'providers/PartnersProvider'
import ImageSlider from 'components/ImageSlider'
import ImageViewer from 'react-native-image-zoom-viewer'

const MenuPage = ({ navigation, route }: MenuPageScreenProps) => {
  const [zoomImages, setZoomImages] = useState<boolean>(false)
  const { menu, language } = route.params
  const drpp = getDrpp()

  return (
    <View
      style={tailwind(
        `flex h-full ${menu.slug === 'contributors' ? 'bg-white' : ''}`
      )}>
      <ScrollView style={tailwind('p-10')}>
        <StyledText el="h1" style={tailwind('pr-4 mb-8')}>
          {menu.title[language]}
        </StyledText>
        {menu.images.length > 0 && (
          <ImageSlider
            images={menu.images}
            handleZoom={() => setZoomImages(true)}
          />
        )}
        <StyledMarkdown>{menu.description[language]}</StyledMarkdown>
        {menu.partners && (
          <View style={tailwind('flex flex-row flex-wrap justify-between')}>
            <TouchableOpacity onPress={() => Linking.openURL(drpp.href)}>
              <Image
                style={{
                  width: '80vw',
                  height: 128,
                  marginBottom: 10
                }}
                source={drpp.image}
                resizeMode="contain"
              />
            </TouchableOpacity>
            {menu.partners
              .filter((p) => p.slug !== 'drpp')
              .map((partner) => (
                <TouchableOpacity
                  key={partner.slug}
                  onPress={() => Linking.openURL(partner.href)}>
                  <Image
                    style={{
                      width: '35vw',
                      height: 128
                    }}
                    source={partner.image}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              ))}
          </View>
        )}
      </ScrollView>
      <TouchableOpacity
        // @ts-ignore
        style={{
          ...tailwind(
            'absolute mt-12 right-0 w-2/12 flex-shrink-0 flex flex-row items-center justify-end rounded-l-full bg-black h-12 w-12'
          ),
          // merp https://stackoverflow.com/questions/61138277/element-with-fixed-position-on-viewport-in-react-native-web
          position: Platform.OS === 'web' ? 'fixed' : undefined
        }}>
        <Icon
          style={tailwind('p-4')}
          name="close"
          size={16}
          color="#fff"
          onPress={() => navigation.goBack()}
        />
      </TouchableOpacity>

      {zoomImages && (
        <Modal visible={true} transparent={true}>
          <ImageViewer
            imageUrls={menu.images.map((image) => ({
              url: image.src
            }))}
            renderHeader={(index) => (
              <View
                style={tailwind(
                  'flex w-full pt-6 h-16 items-end justify-center'
                )}>
                <Icon
                  style={tailwind('p-10')}
                  name="close"
                  color="#fff"
                  size={24}
                  onPress={() => setZoomImages(false)}
                />
              </View>
            )}
          />
        </Modal>
      )}
    </View>
  )
}

export default MenuPage
