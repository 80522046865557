export default [
  {
    id: '1',
    site: 'akem-le-gendre',
    filename: 'Akem_L.mp3',
    uri: ''
  },
  {
    id: '2',
    site: 'arianna-villafane',
    filename: 'Arianna_V.mp3',
    uri: ''
  },
  {
    id: '3',
    site: 'deandre-white',
    filename: 'Deandre_W.mp3',
    uri: ''
  },
  {
    id: '4',
    site: 'devin-dennis',
    filename: 'Devin_D.mp3',
    uri: ''
  },
  {
    id: '5',
    site: 'iris-ivey',
    filename: 'Iris_I.mp3',
    uri: ''
  },
  {
    id: '6',
    site: 'isis-ivey',
    filename: 'Isis_I.mp3',
    uri: ''
  },
  {
    id: '7',
    site: 'jakai-lowe',
    filename: 'Jakai_L.mp3',
    uri: ''
  },
  {
    id: '8',
    site: 'jazmin-nazario',
    filename: 'Jazmine_N.mp3',
    uri: ''
  },
  {
    id: '9',
    site: 'jholdy-cruz',
    filename: 'Jholdy_C.mp3',
    uri: ''
  },
  {
    id: '10',
    site: 'nevaeh-middleton',
    filename: 'Nevaeh_M.mp3',
    uri: ''
  },
  {
    id: '11',
    site: 'tiffany-agront',
    filename: 'Tiffany_A.mp3',
    uri: ''
  },
  {
    id: '12',
    site: 'valentina-tepepa',
    filename: 'Valentina_T.mp3',
    uri: ''
  },
  {
    id: '13',
    site: 'efrain-gonzalez',
    filename: 'Efrain_G.mp3',
    uri: ''
  },
  {
    id: '14',
    site: 'magali',
    filename: 'Magali.mp3',
    uri: ''
  },
  {
    id: '15',
    site: 'marcelo-and-luz',
    filename: 'Marcelo_Luz_O.mp3',
    uri: ''
  },
  {
    id: '16',
    site: 'maria-c-rosa',
    filename: 'Maria_R.mp3',
    uri: ''
  },
  {
    id: '17',
    site: 'titi-rodriguez',
    filename: 'Titi_R.mp3',
    uri: ''
  }
]
