import { data } from 'db'
import { Category, makeCategory } from 'types/category'
import { Document, makeDocument } from 'types/document'
import { Site, makeSite } from 'types/site'

export const documentsByCategory = (category: string): Document[] => {
  return data.documents
    .filter(document => document.category === category)
    .map(document => makeDocument(document))
}

export const documentsByCategories = (categories: string[]): Document[] => {
  return data.documents
    .filter(document => categories.includes(document.category))
    .map(document => makeDocument(document))
}

export const documentsBySite = (site: string): Document[] => {
  return data.documents
    .filter(document => document.site === site)
    .map(document => makeDocument(document))
}
