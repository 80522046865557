export const logos = {
  "logo-outline-blk.png": require('./logo-outline-blk.png'),
  "text-logo-flood.png": require('./text-logo-flood.png'),
  "text-logo.png": require('./text-logo.png'),
  "logo-outline.png": require('./logo-outline.png'),
  "text-logo-flood-2.png": require('./text-logo-flood-2.png'),
}

export const partnerLogos = {
  "bac": require('./bac.png'),
  "bbp": require('./bbp.png'),
  "bkpl": require('./bkpl.png'),
  "blue-city": require('./blue-city.png'),
  "dance-nyc": require('./dance-nyc.png'),
  "drpp": require('./drpp.png'),
  "elp": require('./elp.png'),
  "gbx": require('./gbx.png'),
  "harkness": require('./harkness.png'),
  "katie": require('./katie.png'),
  "leimay": require('./leimay.png'),
  "living": require('./living.png'),
  "nca": require('./nca.png'),
  "not": require('./not.png'),
  "ny-choral": require('./ny-choral.png'),
  "obf": require('./obf.png'),
  "portside": require('./portside.png'),
  "rethink": require('./rethink.png'),
  "reti-center": require('./reti-center.png'),
  "revel": require('./revel.png'),
  "rhf": require('./rhf.png'),
  "rhi": require('./rhi.png'),
  "rrh": require('./rrh.png'),
  "seven": require('./seven.png'),
  "ttt": require('./ttt.png'),
  "whitney": require('./whitney.png')
}
