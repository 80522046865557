import { useState, useEffect } from 'react'

export const useUserLocation = () => {
  const [userLoc, setUserLoc] = useState<[number, number] | undefined>(
    undefined
  )
  const [watchId, setWatchId] = useState<any>(null)
  useEffect(() => {
    if (navigator.geolocation) {
      let id = navigator.geolocation.watchPosition((position: any) => {
        setUserLoc([position.coords.latitude, position.coords.longitude])
      })
      setWatchId(id)
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
    }
  }, [])
  return userLoc
}
