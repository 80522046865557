import { data } from 'db'
import { Language } from 'types/language'
import { imagesBySite } from './ImagesProvider'
import { allPartners } from './PartnersProvider'


export const allMenus = () => {
  return data.menus.map(menu => getMenu(menu['menu-slug']))
}

export const getMenu = (slug: string) => {
  const menu = data.menus.find(menu => menu['menu-slug'] === slug)

  return {
    slug: menu['menu-slug'],
    title: {
      [Language.EN]: menu.title_ENG,
      [Language.ES]: menu.title_SPA
    },
    description: {
      [Language.EN]: menu.description_ENG,
      [Language.ES]: menu.description_SPA
    },
    images: imagesBySite(slug),
    partners: menu['menu-slug'] === 'contributors' ? allPartners() : undefined
  }
}
