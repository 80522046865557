import React, { useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { StyledText } from './StyledText'
import tailwind, { getColor } from 'tailwind-rn'
import { CategoryIcon } from './CategoryIcon'
import { Category } from 'types/category'
import { makeCategoryFromSlug } from 'providers/CategoriesProvider'
import { Language } from 'types/language'

export const LayerToggle: React.FC<{
  category: Category
  language: Language
  on: boolean
  toggle: (layer: string) => void
}> = ({ category, language, on, toggle }) => {
  let [toggleState, setToggleState] = useState<boolean>(on)

  return (
    <View style={tailwind('flex flex-row justify-between mb-4')}>
      <StyledText el="h4">{category.title[language]}</StyledText>
      <TouchableOpacity
        onPress={() => {
          setToggleState(!toggleState)
          toggle(category.slug)
        }}>
        {toggleState ? (
          <CategoryIcon categories={[category]} variant="map" />
        ) : (
          <CategoryIcon categories={[category]} variant="plain" />
        )}
      </TouchableOpacity>
    </View>
  )
}
