import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  ScrollView,
  View,
  TouchableOpacity,
  Dimensions,
  Modal,
  Image,
  Platform
} from 'react-native'
import { tailwind, getColor } from 'styles/tailwind'
import { StyledText, StyledMarkdown } from 'components/StyledText'
import { CategoryIcon } from 'components/CategoryIcon'
import { CATEGORIES } from 'config'
import { AudioPlayer } from 'components/AudioPlayer'
import { SiteModalScreenProps } from 'types/Navigation'
import { makeCategoryFromSlug } from 'providers/CategoriesProvider'
import translations from 'content/translations'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import ImageSlider from 'components/ImageSlider'
import ImageViewer from 'react-native-image-zoom-viewer'

import _ from 'lodash'

const SiteModal = ({ navigation, route }: SiteModalScreenProps) => {
  const language = route.params?.language
  const site = route.params?.site
  let categoriesIncluded = []
  const [zoomImages, setZoomImages] = useState<boolean>(false)

  return (
    <Modal
      animationType="slide"
      transparent={true}
      style={{
        display: 'flex',
        marginTop: 0,
        height: Dimensions.get('window').height
      }}>
      <View
        style={[
          tailwind('h-full mt-10 rounded-full bg-white overflow-hidden'),
          {
            shadowOffset: {
              height: -5,
              width: 10
            },
            shadowOpacity: 0.15,
            shadowRadius: 25
          }
        ]}>
        <ScrollView contentInsetAdjustmentBehavior="automatic">
          <View style={tailwind('pt-12 w-full flex flex-row justify-start')}>
            <View style={tailwind('w-10/12 px-8 pb-8 ml-1')}>
              {!!site.title && <StyledText el="h1">{site.title}</StyledText>}
              {site.address?.length > 0 && (
                <StyledText el="subtitle">{site.address}</StyledText>
              )}
            </View>
          </View>

          <View
            style={tailwind(
              'pb-16 border-solid border-b-0 border-red-500 z-20'
            )}>
            {site.images.length > 0 ? (
              <ImageSlider
                images={site.images}
                handleZoom={() => setZoomImages(true)}
              />
            ) : (
              <View
                style={tailwind(
                  'w-full border-b border-solid border-gray-100'
                )}></View>
            )}

            {site.documents.length > 0 &&
              site.documents
                .filter((document) => {
                  return document.category
                })
                .sort(
                  (docA, docB) =>
                    CATEGORIES.indexOf(docA?.category) -
                    CATEGORIES.indexOf(docB?.category)
                )
                .map((document, index) => {
                  const category = makeCategoryFromSlug(document.category)
                  let printTitle = false
                  if (!categoriesIncluded.includes(document.category)) {
                    printTitle = true
                    categoriesIncluded.push(document.category)
                  }
                  return (
                    <View key={index}>
                      <View
                        style={{
                          paddingTop: 8,
                          paddingBottom: 40,
                          borderStyle: 'solid',
                          borderLeftWidth: 4,
                          borderColor: category.color
                        }}>
                        <View
                          style={{
                            marginLeft: 32,
                            marginRight: 32,
                            paddingBottom: 32
                          }}>
                          {!printTitle ? (
                            <View
                              style={{
                                marginBottom: 40,
                                width: '25%',
                                borderStyle: 'solid',
                                borderTopWidth: printTitle ? 0 : 1,
                                borderColor: getColor('black')
                              }}></View>
                          ) : (
                            <View
                              style={tailwind(
                                'flex flex-row justify-start items-center mb-4'
                              )}>
                              <View
                                style={{
                                  ...tailwind('-ml-8'),
                                  marginRight: 1
                                }}>
                                <CategoryIcon
                                  categories={[category]}
                                  variant="plain"
                                />
                              </View>
                              <StyledText el="h3" c={category.color}>
                                {category.title[language]}
                              </StyledText>
                            </View>
                          )}

                          <StyledMarkdown>
                            {document.description[language]}
                          </StyledMarkdown>

                          {site.audio && <AudioPlayer audio={site.audio} />}

                          {document.contributors && (
                            <View style={tailwind('mt-8')}>
                              <StyledText el="h5">
                                {`${translations.Contributors[language]}: ${document.contributors}`}
                              </StyledText>
                            </View>
                          )}

                          {document.citation[language]?.length > 0 && (
                            <View style={tailwind('mt-8')}>
                              <StyledText el="h5">
                                {`${translations.Citation[language]}`}
                              </StyledText>
                              <StyledMarkdown>
                                {document.citation[language]}
                              </StyledMarkdown>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  )
                })}

            {site.images.map((image) => image.citation).length > 0 && (
              <View style={tailwind('mt-8 ml-1 px-8')}>
                <StyledText el="h5">
                  {`${translations['Image Citation'][language]}`}
                </StyledText>
                <StyledMarkdown>
                  {_.uniq(
                    site.images.map((image) => image.citation[language])
                  ).join('\n\n')}
                </StyledMarkdown>
              </View>
            )}
          </View>
        </ScrollView>

        <TouchableOpacity
          // @ts-ignore
          style={{
            ...tailwind(
              'mt-10 right-0 w-2/12 flex-shrink-0 flex flex-row items-center justify-end rounded-l-full bg-black h-12 w-12'
            ),
            position: Platform.OS === 'web' ? 'fixed' : 'absolute',
            zIndex: 999
          }}
          onPress={() => navigation.goBack()}>
          <Icon style={tailwind('p-4')} name="close" size={16} color="#fff" />
        </TouchableOpacity>
      </View>

      {zoomImages && (
        <Modal visible={true} transparent={true}>
          <ImageViewer
            imageUrls={site.images.map((image) => ({
              url: image.src
            }))}
            renderHeader={(index) => (
              <View
                style={tailwind(
                  'flex w-full pt-6 h-16 items-end justify-center'
                )}>
                <Icon
                  style={tailwind('p-10')}
                  name="close"
                  color="#fff"
                  size={24}
                  onPress={() => setZoomImages(false)}
                />
              </View>
            )}
          />
        </Modal>
      )}
    </Modal>
  )
}

export default SiteModal
