export default  [
    {
      slug: 'general-info',
      title_en: 'General Info',
      title_sp: 'General Info',
      color: '#000',
      icon: ''
    },
    {
      slug: 'climate-change',
      title_en: 'Climate Change',
      title_sp: 'Cambio climático',
      color: '#D7263D',
      icon: 'thermometer-alert'
    },
    {
      slug: 'contaminated-sites',
      title_en: 'Contaminated Sites',
      title_sp: 'Sitios Contaminados',
      color: '#C5D86D',
      icon: 'alert'
    },
    {
      slug: 'historic-landmarks',
      title_en: 'Historical Landmarks',
      title_sp: 'Hitos históricos',
      color: '#1B998B',
      icon: 'map-marker'
    },
    {
      slug: 'unmarked-histories',
      title_en: 'Unmarked Histories',
      title_sp: 'Historias sin marcar',
      color: '#2E294E',
      icon: 'eye'
    },
    {
      slug: 'living-histories',
      title_en: 'Living Histories',
      title_sp: 'Historias vivas',
      color: '#F46036',
      icon: 'microphone-variant'
    },
    {
      slug: 'take-action',
      title_en: 'Take Action',
      title_sp: 'Tome acción',
      color: '#3066BE',
      icon: 'take-action'
    }
  ]
