import { ImageURISource } from 'react-native';
import { Language } from './language';

export interface Image {
  id: number
  site: string
  filename: string
  citation: Record<Language, string>
  categories: string[]
  src: string
}

export const makeImage = ({
  id,
  site,
  filename,
  citation_en,
  citation_sp,
  citation = { [Language.EN]: citation_en || '', [Language.ES]: citation_sp || ''},
  categories,
  src = ''
}): Image => ({
  id,
  site,
  filename,
  citation,
  categories,
  src
})
