import { LatLng } from 'react-native-maps'
import { Category } from './category'
import { Document } from './document'
import { Image } from './image'
import { Audio } from './audio'

export interface Site {
  id: number,
  slug: string
  title: string
  address: string
  latitude: number
  longitude: number
  categories: Category[]
  documents: Document[]
  images: Image[]
  audio?: Audio
}

export const makeSite = ({
  id,
  slug,
  title,
  address,
  latitude = 0,
  longitude = 0,
  categories = [],
  documents = [],
  images = [],
  audio = null
}): Site => ({
  id,
  slug,
  title,
  address,
  latitude,
  longitude,
  categories,
  documents,
  images,
  audio
})
