export default [
  {
    "id":0,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Atlantic Dock, Brooklyn.* George Bradford Brainerd photograph collection, Brooklyn Museum\/Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Muelle del Atl\u00e1ntico, Brooklyn.* Colecci\u00f3n de fotograf\u00eda de George Bradford Brainerd, Museo de Brooklyn\/Biblioteca P\u00fablica de Brooklyn, Centro para la historia de Brooklyn.",
    "src":"001AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":1,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Atlantic Dock, Brooklyn, N.Y. in Gleason's Pictorial on May 19, 1851.* &copy; Miriam and Ira D. Wallach Division, The New York Public Library.",
    "citation_sp":"*Muelle del Atl\u00e1ntico, Brooklyn, N.Y. en las im\u00e1genes de Gleason en el 19 de mayo de 1851.* &copy; Divisi\u00f3n de Miriam e Ira Wallach de la Biblioteca P\u00fablica de Nueva York.",
    "src":"002AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":2,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Laid up for the winter---Atlantic Basin, Brooklyn in Harper\u2019s Magazine,1873.* &copy; Miriam and Ira D. Wallach Division, The New York Public Library.",
    "citation_sp":"*Colocado para el invierno---Cuenca del Atl\u00e1ntico, Brooklyn en la revista Harper\u2019s Magazine en 1873.* &copy; Divisi\u00f3n de Miriam e Ira Wallach de la Biblioteca P\u00fablica de Nueva York.",
    "src":"003AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":3,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Atlantic Basin in Red Hook, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Cuenca del Atl\u00e1ntico en Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"004AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":4,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Atlantic Basin in Red Hook, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Cuenca del Atl\u00e1ntico en Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"005AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":5,
    "site":"slave-ship-erie-in-atlantic-basin",
    "citation_en":"*Atlantic Basin, 1903.* Courtesy of NYC Municipal Archives and Department of Records & Information Service",
    "citation_sp":"*Cuenca del Atl\u00e1ntico, 1903.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York.",
    "src":"006AtlanticBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":6,
    "site":"batmobile-garage",
    "citation_en":"*The Batmobile Garage in Red Hook, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*El garage del Batim\u00f3vil en Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"001BatmobileGarage.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":7,
    "site":"blue-city",
    "citation_en":"*Blue City, illustration.* Courtesy of The RETI Center.",
    "citation_sp":"*Blue City, Ilustraci\u00f3n.* Cortes\u00eda del RETI Center.",
    "src":"001BlueCity.jpeg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":8,
    "site":"blue-city",
    "citation_en":"*Blue City, diagram.* Courtesy of The RETI Center. ",
    "citation_sp":"*Blue City, diagrama.* Cortes\u00eda del RETI Center.",
    "src":"002BlueCity.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":9,
    "site":"brooklyn-clay-retort",
    "citation_en":"*Brooklyn Daily Eagle Almanac, 1897.* Courtesy of The Library of Congress.",
    "citation_sp":"*Almanaque del Brooklyn Daily Eagle de 1897.* Cortes\u00eda de la biblioteca del Congreso.",
    "src":"001BKClayRetort.jpg",
    "categories":[
      "Contaminated Site"
    ]
  },
  {
    "id":10,
    "site":"how-did-colonialism-affect-the-brooklyn-waterfront",
    "citation_en":"*\\\"Plan of the town of Brooklyn and part of Long Island\\\", 1767.* Map by Bernard Ratzer. &copy; Lionel Pincus and Princess Firyal Map Division, The New York Public Library.",
    "citation_sp":"*\\\"Plano del pueblo de Brooklyn y parte de Long Island\\\", 1767.* Mapa de Bernard Ratzer. &copy; DIvisi\u00f3n de mapas de Lionel Pincus y Princess Firyal, Biblioteca p\u00fablica de Nueva York.",
    "src":"001DutchColonialMaps.jpg",
    "categories":[
      "Accordian Menu"
    ]
  },
  {
    "id":11,
    "site":"erie-basin",
    "citation_en":"*Buffalo & Erie Basin, 1911. &copy; W.H. Brandel.* Courtesy of The Library of Congress. ",
    "citation_sp":"*Cuenca de Buffalo y Erie, 1911. &copy; W.H. Brandel.* Cortes\u00eda de la Biblioteca del Congreso.",
    "src":"001ErieBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":12,
    "site":"erie-basin",
    "citation_en":"*Erie Basin, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Cuenca de Erie, 1941. Mejoras de Gowanus, 1 de noviembre de 1941.* Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"002ErieBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":13,
    "site":"erie-basin",
    "citation_en":"*Erie Basin, Red Hook.* Photograph by Whitney Browne, 2020.",
    "citation_sp":"*Cuenca de Erie, Red Hook.* Fotograf\u00eda de Whitney Browne.",
    "src":"003ErieBasin.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":14,
    "site":"fort-defiance",
    "citation_en":"*Plan of the Battle of Long Island and of the Brooklyn Defences, August 27th, 1776.* &copy; Lionel Pincus and Princess Firyal Map Division, The New York Public Library. ",
    "citation_sp":"*Plan de la batalla de Long Island y de las defensas de Brooklyn, 27 de agosto de 1776.* &copy; Divisi\u00f3n de mapas de Lionel Pincus y Princess Firyal, Biblioteca P\u00fablica de Nueva York.\n",
    "src":"001FortDefiance.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":15,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal & 9th St. & Smith Station. January 21, 1978. Photograph by Dinanda H. Nooney.* &copy; Miriam and Ira D. Wallach Division, The New York Public Library.",
    "citation_sp":"*Canal de Gowanus y calle 9 y estaci\u00f3n de Smith St., 21 de enero de 1978. Fotograf\u00eda de Dinanda H. Nooney.* &copy; Divisi\u00f3n Miriam e Ira D. Wallach, Biblioteca P\u00fablica de Nueva York.",
    "src":"001GowanusCanal.jpeg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":16,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal - Brooklyn - Hamilton Avenue Bridge (North), 1930. Photograph by Ewing Galloway.* &copy; Irma and Paul Milstein Division, The New York Public Library.",
    "citation_sp":"*Canal de Gowanus - Brooklyn - Puente de la Avenida Hamilton (Norte), 1930. Fotograf\u00eda de Ewing Galloway.* &copy; Divisi\u00f3n de Irma y Paul Milstein, Biblioteca P\u00fablica de Nueva York.",
    "src":"002GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":17,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Canal de Gowanus, 1941. Mejoras de Gowanus, 1 de noviembre de 1941.* Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia \n",
    "src":"003GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":18,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"004GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":19,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"005GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":20,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"006GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":21,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"007GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":22,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.\n",
    "src":"008GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":23,
    "site":"gowanus-canal",
    "citation_en":"*Gowanus Canal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Canal de Gowanus, 2020.* Fotograf\u00eda de Whitney Browne.\n",
    "src":"009GowanusCanal.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":24,
    "site":"gowanus-expressway",
    "citation_en":"*View of the Gowanus Improvement, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Vista de las mejoras de Gowanus, 1941.* Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"001GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":25,
    "site":"gowanus-expressway",
    "citation_en":"*Owl's Head Intersection, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Intersecci\u00f3n de Owl's Head, 1941. Mejoras de Gowanus, 1 de noviembre de 1941.* Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"002GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":26,
    "site":"gowanus-expressway",
    "citation_en":"*Construction along Third Avenue, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Construcci\u00f3n a lo largo de la 3era avenida, 1941.  Mejoras de Gowanus, 1 de noviembre de 1941.*  Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"003GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":27,
    "site":"gowanus-expressway",
    "citation_en":"*Steel Construction, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Construcci\u00f3n de acero, 1941. Mejoras de Gowanus, 1 de noviembre de 1941.*  Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"004GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":28,
    "site":"gowanus-expressway",
    "citation_en":"*Steel Construction, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"* Construcci\u00f3n de acero, 1941. Gowanus Improvement, 1 de noviembre de 1941.* Triborough Bridge Authority y Robert Moses, presidente. Cortes\u00eda de la Colecci\u00f3n de la Biblioteca Seymour B. Durst Old York, Biblioteca Avery de Arquitectura y Bellas Artes, Universidad de Columbia.",
    "src":"005GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":29,
    "site":"gowanus-expressway",
    "citation_en":"*Gowanus Expressway, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*Autopista Gowanus, 2020.* Fotograf\u00eda de Whitney Browne. ",
    "src":"006GowanusExpressway.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":30,
    "site":"who-were-the-carnarsee-of-lenapehoking",
    "citation_en":"*Indian villages, paths, ponds and  places in Kings County; [1946; 1946].* Map Collection, B B-1946.Fl; Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":" *VIllas, caminos, estanques y lugares indios en el Condado de Kings; [1946; 1946].* Colecci\u00f3n de mapas, B B-1946.Fl; Biblioteca P\u00fablica de Brooklyn, Centro para la Historia de Brooklyn.",
    "src":"001Lenapehoking.jpg",
    "categories":[
      "Accordian Menu"
    ]
  },
  {
    "id":31,
    "site":"who-were-the-carnarsee-of-lenapehoking",
    "citation_en":"*The Indian tribes of Long Island: designed, compiled and lithographed by Victor G. Becker; [ca. 1934].* Map Collection, L.I.-1934.Fl; Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Las tribus Indias de Long Island: designed, compilado y litografiado por Victor G. Becker; [ca. 1934].* Colecci\u00f3n de mapas, L.I.-1934.Fl; Biblioteca P\u00fablica de Brooklyn, Centro para la Historia de Brooklyn.",
    "src":"002Lenaphoking.jpg",
    "categories":[
      "Accordian Menu"
    ]
  },
  {
    "id":32,
    "site":"lidgerwood-manufacturing",
    "citation_en":"*Mainworks and Foundry Department, 1912.* From Lidgerwood Cableways Catalog, 1912, Lidgerwood Manufacturing Co. ",
    "citation_sp":"*Departamento de Obras Principales y Fundici\u00f3n, 1912.* Del cat\u00e1logo de telef\u00e9ricos de Lidgerwood, 1912, Compa\u00f1\u00eda Lidgerwood Manufacturing Co. ",
    "src":"001Lindgerwood.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":33,
    "site":"lidgerwood-manufacturing",
    "citation_en":"*Lidgerwood Manufacturing Site, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*SItio de Lidgerwood Manufacturing, 2020.* Fotograf\u00eda de Whitney Browne. ",
    "src":"002Lidgerwood.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":34,
    "site":"lidgerwood-manufacturing",
    "citation_en":"*Lidgerwood Manufacturing Site, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*SItio de Lidgerwood Manufacturing, 2020.* Fotograf\u00eda de Whitney Browne. ",
    "src":"003Lidgerwood.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":35,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"001Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":36,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"002Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":37,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"003Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":38,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"004Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":39,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"005Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":40,
    "site":"red-hook-ballfields",
    "citation_en":"*Red Hook Ballfields, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Parques de pelota Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"006Ballfields.jpg",
    "categories":[
      "Unmarked Histories\/Contaminated Sites"
    ]
  },
  {
    "id":41,
    "site":"red-hook-cruise-ship-terminal",
    "citation_en":"*Red Hook Cruise Ship Terminal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Terminal de cruceros de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"001CruiseShipTerminal.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":42,
    "site":"red-hook-grain-terminal",
    "citation_en":"*[Grain Terminal at Gowanus Canal], circa 1930, gelatin silver print, V1973.5.978.* Brooklyn photograph and illustration collection, ARC.202; Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*[Terminal de granos en el canal de Gowanus], circa 1930, impresi\u00f3n en gelatina de plata, V1973.5.978.* Colecci\u00f3n de ilustraci\u00f3n y fotograf\u00eda de Brooklyn, ARC.202; Biblioteca P\u00fablica de Brooklyn, Centro para la Historia de Brooklyn.",
    "src":"001GrainTerminal.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":43,
    "site":"red-hook-grain-terminal",
    "citation_en":"*Red Hook Grain Terminal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Terminal de Granos de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"002GrainTerminal.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":44,
    "site":"red-hook-grain-terminal",
    "citation_en":"*Red Hook Grain Terminal, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Terminal de Granos de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"003GrainTerminal.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":45,
    "site":"red-hook-houses",
    "citation_en":"*Red Hook Housing Project, Brooklyn, New York, 1939.* Photograph by Gottscho-Schleisner, Inc. Courtesy of the Library of Congress.",
    "citation_sp":"*Proyecto de vivienda de Red Hook, Brooklyn, New York, 1939.* Fotograf\u00eda de Gottscho-Schleisner, Inc. Cortes\u00eda de la Biblioteca del Congreso.",
    "src":"001RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":46,
    "site":"red-hook-houses",
    "citation_en":"*Red Hook Houses, Brooklyn, New York, 1940.* Photograph by Gottscho-Schleisner, Inc. Courtesy of the Library of Congress.",
    "citation_sp":" *Casas de Red Hook, Brooklyn, New York, 1940.* Fotograf\u00eda de Gottscho-Schleisner, Inc. Cortes\u00eda de la Biblioteca del Congreso.",
    "src":"002RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":47,
    "site":"red-hook-houses",
    "citation_en":"*Model of Red Hook Houses, 1934-1945.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Modelo de las casas de Red Hook, 1934-1945.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York.",
    "src":"003RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":48,
    "site":"red-hook-houses",
    "citation_en":"*Red Hook Houses, Otsego and Clinton Streets, Brooklyn. Rendering, aerial view, 1930-1939.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Casas de Red Hook Houses, Calles Otsego y Clinton, Brooklyn. Representaci\u00f3n, vista area, 1930-1939.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York ",
    "src":"005RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":49,
    "site":"red-hook-houses",
    "citation_en":"*Aerial view, Red Hook Houses, Brooklyn, 1930-1939.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Vista a\u00e9rea, Casas de Red Hook, Brooklyn, 1930-1939.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York",
    "src":"006RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":50,
    "site":"red-hook-houses",
    "citation_en":"Mrs. Caputo using the incinerator, 1942. \nPhotograph by Arthur Rothstein. Courtesy of the Library of Congress.",
    "citation_sp":"*La Sra. James Caputo usando el incinerador, 1942.* Fotograf\u00eda de Arthur Rothstein. Cortes\u00eda de la Biblioteca del Congreso.",
    "src":"007RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":51,
    "site":"red-hook-houses",
    "citation_en":"*Mrs. James Caputo in the modern kitchen of her apartment, pouring milk for Annette and Jimmy, 1942.* Photograph by Arthur Rothstein. Courtesy of the Library of Congress. ",
    "citation_sp":"*La se\u00f1ora James Caputo en la moderna cocina de su apartamento, sirviendo leche para Annette y Jimmy, 1942.* Fotograf\u00eda de Arthur Rothstein. Cortes\u00eda de la Biblioteca del Congreso.",
    "src":"008RedHookHouses.jpg",
    "categories":[
      "Climate Change, Unmarked Histories"
    ]
  },
  {
    "id":52,
    "site":"red-hook-initiative",
    "citation_en":"*Youth Leaders at Red Hook Initiative.* Courtesy of RHI Development.",
    "citation_sp":"*Youth Leaders (j\u00f3venes l\u00edderes) en el Red Hook Initiative.* Cortes\u00eda de RHI Development.",
    "src":"001RedHookInitiative.jpg",
    "categories":[
      "Climate Change, Take Action"
    ]
  },
  {
    "id":53,
    "site":"red-hook-initiative",
    "citation_en":"*Activism at Red Hook Initiative.* Courtesy of RHI Development.",
    "citation_sp":"*Activismo en el Red Hook Initiative.* Cortes\u00eda de RHI Development.",
    "src":"002RedHookInitiative.jpg",
    "categories":[
      "Climate Change, Take Action"
    ]
  },
  {
    "id":54,
    "site":"red-hook-initiative",
    "citation_en":"*In Community at Red Hook Initiative.* Courtesy of RHI Development.",
    "citation_sp":"*En comuinidad en el Red Hook Initiative.* Cortes\u00eda de RHI Development.",
    "src":"003RedHookInitiative.jpg",
    "categories":[
      "Climate Change, Take Action"
    ]
  },
  {
    "id":55,
    "site":"red-hook-recreation-center-pool",
    "citation_en":"*Red Hook Pool on August 1, 1940.* Courtesy of NYC Department of Parks & Recreation Archives.",
    "citation_sp":"*Piscina de Red Hook en Agosto 1 de 1940.* Cortes\u00eda de los Archivos del Departamento de Parques y Recreaci\u00f3n de la Ciudad de Nueva York.",
    "src":"001RedHookPoolRec.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":56,
    "site":"red-hook-recreation-center-pool",
    "citation_en":"*Red Hook Pool Play Center, 1936.* Courtesy of NYC Department of Parks & Recreation Archives.",
    "citation_sp":"*Centro de Juegos de la Piscina de Red Hook, 1936.* Cortes\u00eda de los Archivos del Departamento de Parques y Recreaci\u00f3n de la Ciudad de Nueva York.",
    "src":"002RedHookPoolRec.png",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":57,
    "site":"red-hook-recreation-center-pool",
    "citation_en":"*Red Hook Pool, circa 1940.* Courtesy of Atlas Obscura.",
    "citation_sp":"*Piscina de Red Hook, circa 1940. * Cortes\u00eda de Atlas Obscura.",
    "src":"003RedHookPoolRec.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":58,
    "site":"red-hook-recreation-center-pool",
    "citation_en":"*Masked locals play chess outside of closed entrance to the Red Hook Pool durring COVID-19, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*La gente local juega ajedrez, durante el COVID-19, con sus m\u00e1scaras puestas en las afueras de la entrada cerrada de la piscina de Red Hook en el 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"004RedHookPoolRec.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":59,
    "site":"red-hook-recreation-center-pool",
    "citation_en":"*Sol Goldman Pool in the Red Hook Play Center.* Photograph by Whitney Browne, 2020.",
    "citation_sp":"*Piscina Sol Goldman en el Centro de juegos de Red Hook.* Fotograf\u00eda de Whitney Browne.",
    "src":"005RedHookPool.jpg",
    "categories":[
      "Historic Landmarks"
    ]
  },
  {
    "id":60,
    "site":"red-hook-stores",
    "citation_en":"*Red Hook Stores, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Tiendas de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"001RedHookStores.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":61,
    "site":"red-hook-stores",
    "citation_en":"*Red Hook Stores, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Tiendas de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"002RedHookStores.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":62,
    "site":"red-hook-stores",
    "citation_en":"*Red Hook Stores, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Tiendas de Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"003RedHookStores.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":63,
    "site":"revere-sugar",
    "citation_en":"*Filling and sewing bags of granulated sugar, New York.* Photographer and year unknown. &copy; Irma and Paul Milstein Division, The New York Public Library.",
    "citation_sp":"*Llenado y cosido de sacos de az\u00facar granulada, Nueva York.* Fot\u00f3grafo y a\u00f1o desconocido. &copy; Divisi\u00f3n Irma y Paul Milstein, Biblioteca P\u00fablica de Nueva York.",
    "src":"001RevereSugar.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":64,
    "site":"revere-sugar",
    "citation_en":"*The Revere Sugar Refinery in 1999.* Photograph by Susan De Vries.",
    "citation_sp":"*La refiner\u00eda de az\u00facar Revere en 1999.* Fotograf\u00eda de Susan De Vries.",
    "src":"002RevereSugar.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":65,
    "site":"tin-city-hooverville",
    "citation_en":"*Red Hook Hooverville \\\"Tin City\\\" with Grain Terminal, circa 1930.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":null,
    "src":"001TinCity.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":66,
    "site":"tin-city-hooverville",
    "citation_en":"*Red Hook Hooverville \\\"Tin City\\\" with Grain Terminal, circa 1931.* Photographer unknown.",
    "citation_sp":"*Hooverville de Red Hook \\\"Tin City \\\" con terminal de granos, circa 1930.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York.",
    "src":"002TinCity.png",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":67,
    "site":"tin-city-hooverville",
    "citation_en":"*Erie Basin, 1941. Gowanus Improvement, November 1, 1941.* Triborough Bridge Authority and Robert Moses, Chairman. Courtesy of Seymour B. Durst Old York Library Collection, Avery Architectural & Fine Arts Library, Columbia University.",
    "citation_sp":"*Hooverville de Red Hook \\\"Tin City \\\" con terminal de granos, circa 1931.* Fot\u00f3grafo desconocido.",
    "src":"003TinCity.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":68,
    "site":"tin-city-hooverville",
    "citation_en":"*Red Hook Hooverville \\\"Tin City\\\", circa 1930-1932.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Cuenca Erie, 1941. Mejoras de Gowanus, Noviembre 1 de 1941.* Autoridad del Puente de Triborough y Robert Moses, Director. Cortes\u00eda de la Colecci\u00f3n Seymour B. Durst de la Biblioteca de Old York, Biblioteca de Bellas Artes y Arquitectura de Avery, Universidad de Columbia.",
    "src":"004TinCity.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":69,
    "site":"todd-shipyard",
    "citation_en":"*Brooklyn: Beard Street - Richard Street, June 6, 1931.* Photograph by Percy Loomis Sperr. &copy; Irma and Paul Milstein Division, The New York Public Library.",
    "citation_sp":"*Hooverville de Red Hook \\\"Tin City \\\", circa 1930-1932.* Cortes\u00eda del Archivo Municipal y el Departamento de Registro y Servicio de Informaci\u00f3n de la Ciudad de Nueva York",
    "src":"001RobinsDryDock.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":70,
    "site":"todd-shipyard",
    "citation_en":"*Red Hook Graving Dock, circa 1930.* Courtesy of Atlas Obscura.",
    "citation_sp":"*Brooklyn: Calle Beard - Calle Richard, June 6 de 1931.* Fotograf\u00eda de  Percy Loomis Sperr. &copy; Divisi\u00f3n Irma y Paul Milstein, Biblioteca P\u00fablica de Nueva York.",
    "src":"001ToddShipyard.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":71,
    "site":"todd-shipyard",
    "citation_en":"*Todd Shipyard Graving Dock, 1928.* Courtesy of Underwood Archives.",
    "citation_sp":"*Dique Seco de Red Hook, circa 1930.* Cortes\u00eda de Atlas Obscura.",
    "src":"002ToddShipyard.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":72,
    "site":"todd-shipyard",
    "citation_en":"*Anne Moses in Todd Shipyard Helmet.* Courtesy of the collection of Michael A. Levine.",
    "citation_sp":"*Dique Seco de Todd Shipyard, circa 1928.* Cortes\u00eda de Underwood Archives.",
    "src":"003ToddShipyard.jpg",
    "categories":[
      "Unmarked Histories"
    ]
  },
  {
    "id":73,
    "site":"valentino-park",
    "citation_en":"*Hurricane Sandy: Red Hook\u2019s Storm Surge map, 2012.* Map by Jim McMahon. Courtesy of Red Hook WaterStories by PortSide NewYork, 2020.",
    "citation_sp":"*Anne Moses en un casco de Todd Shipyard.* Cortes\u00eda de la colecci\u00f3n de Michael A. Levine.",
    "src":"001ValentinoPark.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":74,
    "site":"valentino-park",
    "citation_en":"*Valentino Park, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*Hurac\u00e1n Sandy: Mapa de marejada cicl\u00f3nica de Red Hook, 2012.* Mapa de Jim McMahon. Cortes\u00eda de Red Hook WaterStories de PortSide Nueva York, 2020.",
    "src":"002ValentinoPark.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":75,
    "site":"valentino-park",
    "citation_en":"*Valentino Park, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*Parque Valentino, 2020.* Fotograf\u00eda de Whitney Browne",
    "src":"003ValentinoPark.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":76,
    "site":"valentino-park",
    "citation_en":"*Valentino Park, 2020.* Photograph by Whitney Browne. ",
    "citation_sp":"*Parque Valentino, 2020.* Fotograf\u00eda de Whitney Browne",
    "src":"004ValentinoPark.jpg",
    "categories":[
      "Climate Change"
    ]
  },
  {
    "id":77,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"Parque Valentino, 2020. Fotograf\u00eda de Whitney Browne",
    "src":"001RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":78,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"002RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":79,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"003RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":80,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"004RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":81,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"005RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":82,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"006RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":83,
    "site":"red-hook-community-farms",
    "citation_en":"*Red Hook Farms, 2020.* Photograph by Whitney Browne.",
    "citation_sp":"*Granjas Red Hook, 2020.* Fotograf\u00eda de Whitney Browne.",
    "src":"007RedHookFarms.jpg",
    "categories":[
      "Take Action"
    ]
  },
  {
    "id":84,
    "site":"what-is-superstorm-sandy",
    "citation_en":"*Superstorm Sandy, 2012.* Photograph by Pascal Perich.",
    "citation_sp":"*S\u00faper tormenta Sandy, 2012.* Fotograf\u00eda de Pascal Perich.",
    "src":"001SuperstormSandy.jpg",
    "categories":[
      "General Info"
    ]
  },
  {
    "id":85,
    "site":"what-is-superstorm-sandy",
    "citation_en":"*Superstorm Sandy, 2012.* Photograph by Pascal Perich.",
    "citation_sp":"*S\u00faper tormenta Sandy, 2012.* Fotograf\u00eda de Pascal Perich.",
    "src":"002SuperstormSandy.jpg",
    "categories":[
      "General Info"
    ]
  },
  {
    "id":86,
    "site":"what-is-superstorm-sandy",
    "citation_en":"*Superstorm Sandy, 2012.* Photograph by Pascal Perich.",
    "citation_sp":"*S\u00faper tormenta Sandy, 2012.* Fotograf\u00eda de Pascal Perich.",
    "src":"003SuperstormSandy.jpg",
    "categories":[
      "General Info"
    ]
  },
  {
    "id":86,
    "site":"50-kent-ave",
    "citation_en":"*50 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*50 Kent Avenue* por Elyse Mertz Fotograf\u00edas, 2021.",
    "src":"50KentAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":87,
    "site":"50-kent-ave",
    "citation_en":"*50 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*50 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"50KentAvenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":90,
    "site":"230-kent-ave",
    "citation_en":"*230 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*230 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"230KentAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":91,
    "site":"230-kent-ave",
    "citation_en":"*230 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*230 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"230KentAvenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":92,
    "site":"416-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"420KentAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":93,
    "site":"416-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"420KentAvenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":94,
    "site":"416-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"420KentAvenue003.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":95,
    "site":"416-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"420KentAvenue004.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":96,
    "site":"470-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"470KentAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":97,
    "site":"470-kent-ave",
    "citation_en":"*420 Kent Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*420 Kent Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"470KentAvenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":98,
    "site":"mccarren-pool",
    "citation_en":"*McCarren Park Swimming pool on July 12, 1937.* Photographer unknown. Courtesy of NYC Municipal Archives and Department of Records & Information Services, NYC Department of Parks & Recreation.",
    "citation_sp":"*Piscina del parque McCarren el 12 de julio de 1937.* Fot\u00f3grafo desconocido. Cortes\u00eda de NYC Archivos Municipales  y Departamento de  Records y Servicios Informativos, NYC Departmento de parques y recreaciones.",
    "src":"McCarrenPool001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":99,
    "site":"mccarren-pool",
    "citation_en":"*McCarren Park: front pool entrance on April 15, 1937.* Photographer unknown. Courtesy of NYC Municipal Archives and Department of Records & Information Services, NYC Department of Parks & Recreation.",
    "citation_sp":"*McCarren Park: entrada frontal a la piscina el 15 de abril de 1937.* Fot\u00f3grafo desconocido. Cortes\u00eda de NYC Municipal Archives y Department of Records & Information Services, NYC Department of Parks & Recreation.",
    "src":"McCarrenPool002.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":100,
    "site":"mccarren-pool",
    "citation_en":"*Swimming contest at McCarren Pool on September 20, 1944.* Photographer unknown. Courtesy of NYC Municipal Archives and Department of Records & Information Services, NYC Department of Parks & Recreation.",
    "citation_sp":"*Concurso de nataci\u00f3n en McCarren Pool el 20 de septiembre de 1944.* Fot\u00f3grafo desconocido. Cortes\u00eda de NYC Municipal Archives y Department of Records & Information Services, NYC Department of Parks & Recreation.",
    "src":"McCarrenPool003.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":101,
    "site":"mccarren-pool",
    "citation_en":"*The McCarren Park swimming pool [and bathhouse], located at Lorimer St. and Driggs Ave. ... will have its formal opening tomorrow night with speeches by Mayor LaGuardia and ... other dignitaries.* Photographer unknown. Published in The Brooklyn Eagle, 1936. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*La piscina [y la casa de ba\u00f1os] de McCarren Park, ubicada en Lorimer St. y Driggs Ave. ... tendr\u00e1 su inauguraci\u00f3n formal ma\u00f1ana por la noche con discursos del alcalde LaGuardia y ... otros dignatarios.* Fot\u00f3grafo desconocido. Publicado en The Brooklyn Eagle, 1936. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n Brooklyn.",
    "src":"McCarrenPool004.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":102,
    "site":"mccarren-pool",
    "citation_en":"*McCarren Park Pool* by Elyse Mertz Photography, 2021. \n",
    "citation_sp":"*Piscina del parque McCarren* por Elyse Mertz Fotografias , 2021.",
    "src":"McCarrenPool005.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":103,
    "site":"mccarren-pool",
    "citation_en":"*McCarren Park Pool* by Elyse Mertz Photography, 2021. \n",
    "citation_sp":"*Piscina del parque McCarren* por Elyse Mertz Fotografias , 2021.",
    "src":"McCarrenPool006.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":104,
    "site":"gretsch-building",
    "citation_en":"*Section of Wythe Avenue with several commercial buildings, section of elevated highway on left, two pedestrians (women), truck, and portion of automobile in far right foreground. Building in right foreground has sign, \\\"Salt for all purposes\\\", and ten-story building in background has sign,  \\\"Gretsch\\\".* Photographer unknown, circa 1930. Courtesy of Brooklyn Daily Eagle photographs, Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Secci\u00f3n de Wythe Avenue con varios edificios comerciales, secci\u00f3n de autopista elevada A la izquierda, dos peatones (mujeres), cami\u00f3n y parte del autom\u00f3vil en primer plano a la derecha. El edificio en primer plano a la derecha tiene el letrero  \\\"Salt para todos los prop\u00f3sitos\\\" y el edificio de diez pisos en el fondo tiene el letrero  \\\"Gretsch\\\".* Fot\u00f3grafo desconocido, alrededor de 1930. Cortes\u00eda de fotograf\u00edas del Brooklyn Daily Eagle, Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"GretschBuilding001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":105,
    "site":"gretsch-building",
    "citation_en":"*Gretsch Building* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Edificio Gretsch* por Elyse Mertz Fotografias, 2021.",
    "src":"GretschBuilding002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":106,
    "site":"gretsch-building",
    "citation_en":"*Gretsch Building* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Edificio Gretsch* por Elyse Mertz Fotografias, 2021.",
    "src":"GretschBuilding003.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":107,
    "site":"MPJ-park",
    "citation_en":"*Marsha P. Johnson hands out flyers for support of gay students at N.Y.U.. Photograph by Diana Davies, 1970.* &copy; Manuscripts and Archives Division, The New York Public Library Digital Collections. ",
    "citation_sp":"*Marsha P. Johnson reparte volantes para apoyar a los estudiantes homosexuales en la Universidad de Nueva York. Fotograf\u00eda de Diana Davies, 1970.* &copy; Divisi\u00f3n de Manuscritos y Archivos, Colecciones Digitales de la Biblioteca P\u00fablica de Nueva York.",
    "src":"MarshaP.Johnson001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":108,
    "site":"MPJ-park",
    "citation_en":"*Marsha P. Johnson pickets Bellevue Hospital to protest treatment of street people and gays. Photograph by Diana Davies, 1968 - 1975.* &copy; Manuscripts and Archives Division, The New York Public Library Digital Collections.",
    "citation_sp":"*Marsha P. Johnson hace piquetes en el Hospital de Bellevue para protestar por el trato que reciben las personas de la calle y los homosexuales. Fotograf\u00eda de Diana Davies, 1968 - 1975.* &copy; Divisi\u00f3n de Manuscritos y Archivos, Colecciones Digitales de la Biblioteca P\u00fablica de Nueva York.",
    "src":"MarshaP.Johnson002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":110,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge, which opened in 1903, under construction.* Photograph by Julius Wilcox, circa 1900. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Puente de Williamsburg, que se inaugur\u00f3 en 1903, en construcci\u00f3n.* Fotograf\u00eda de Julius Wilcox, alrededor de 1900. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"WilliamsburgBridge001.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":111,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge, which opened in 1903, with several large posters, attached to wall or fence, in foreground.* Photograph by Julius Wilcox, circa 1900. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Williamsburg Bridge, que se inaugur\u00f3 en 1903, con varios carteles grandes, pegados a la pared o cerca, en primer plano.* Fotograf\u00eda de Julius Wilcox, alrededor de 1900. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"WilliamsburgBridge002.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":112,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge as seen from the Brooklyn side.* Photograph by Julius Wilcox, circa 1900. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Puente de Williamsburg visto desde el lado de Brooklyn.* Fotograf\u00eda de Julius Wilcox, alrededor de 1900. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"WilliamsburgBridge003.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":113,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge* by Elyse Mertz Photography, 2021. ",
    "citation_sp":"*Puente de Williamsburg* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgBridge004.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":114,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge* by Elyse Mertz Photography, 2021. ",
    "citation_sp":"*Puente de Williamsburg* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgBridge005.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":115,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge* by Elyse Mertz Photography, 2021. ",
    "citation_sp":"*Puente de Williamsburg* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgBridge006.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":116,
    "site":"williamsburg-bridge",
    "citation_en":"*Williamsburg Bridge* by Elyse Mertz Photography, 2021. ",
    "citation_sp":"*Puente de Williamsburg* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgBridge007.jpg",
    "categories":[
      "historic-landmark"
    ]
  },
  {
    "id":117,
    "site":"mccarren-park",
    "citation_en":"*View of a portion of McCarren Park, showing an athletic track, and a large building under construction in the background.* Photograph by Anders Goldfarb, 1954. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Vista de una parte del parque McCarren, que muestra la pista de atletismo y un gran edificio en construcci\u00f3n al fondo.* Fotograf\u00eda de Anders Goldfarb, 1954. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"McCarrenPark001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":119,
    "site":"mccarren-park",
    "citation_en":"*Indian villages, paths, ponds and  places in Kings County; [1946; 1946].* Map Collection, B B-1946.Fl; Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":" *VIllas, caminos, estanques y lugares indios en el Condado de Kings; [1946; 1946].* Colecci\u00f3n de mapas, B B-1946.Fl; Biblioteca P\u00fablica de Brooklyn, Centro para la Historia de Brooklyn.",
    "src":"McCarrenPark002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":123,
    "site":"satmar-hasidic-community",
    "citation_en":"*Close-up of facade of three-story brownstone house, probably located at 616 Bedford Avenue in Williamsburg; sign written in Hebrew over doorway: [translation] \\\"Agudath Israel of America;\\\" two pedestrians [boy and girl] walking on sidewalk in foreground.* Photograph by Irving I. Herzberg, 1965. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Primer plano de la fachada de una casa de piedra rojiza de tres pisos, probablemente ubicada en 616 Bedford Avenue en Williamsburg; cartel escrito en hebreo sobre la entrada: [traducci\u00f3n] \\\"Agudath Israel of America \\\"; dos peatones [ni\u00f1o y ni\u00f1a] caminando sobre la acera en primer plano.* Fotograf\u00eda de Irving I. Herzberg, 1965. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"HasidicCommunity001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":124,
    "site":"satmar-hasidic-community",
    "citation_en":"*Close-up of facade of large, brick building with porches, ornamental ledges, an arched doorway and round half-tower, being a Yeshiva school at the corner of Bedford Avenue and Rodney Avenue; woman, several children, and street signs are in foreground, and portions of a school bus and citybus in far left and far right foreground.* Photograph by Irving I. Herzberg, 1965. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Primer plano de la fachada de un gran edificio de ladrillo con porches, repisas ornamentales, una entrada arqueada y una media torre redonda, siendo una escuela Yeshiva en la esquina de Bedford Avenue y Rodney Avenue; una mujer, varios ni\u00f1os y los letreros de las calles est\u00e1n en primer plano, y partes de un autob\u00fas escolar y un autob\u00fas urbano en el primer plano a la izquierda y a la derecha.* Fotograf\u00eda de Irving I. Herzberg, 1965. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"HasidicCommunity002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":125,
    "site":"satmar-hasidic-community",
    "citation_en":"*One of the oldest synagogues on Keap Street in Williamsburg.* Photograph by Irving I. Herzberg, 1965. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Una de las sinagogas m\u00e1s antiguas de Keap Street en Williamsburg.* Fotograf\u00eda de Irving I. Herzberg, 1965. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"HasidicCommunity003.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":126,
    "site":"satmar-hasidic-community",
    "citation_en":"*Hasidic Community* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Comunidad jas\u00eddica* por Elyse Mertz Fotografias, 2021.",
    "src":"HasidicCommunity004.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":127,
    "site":"satmar-hasidic-community",
    "citation_en":"*Hasidic Community* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Comunidad jas\u00eddica* por Elyse Mertz Fotografias, 2021.",
    "src":"HasidicCommunity005.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":128,
    "site":"satmar-hasidic-community",
    "citation_en":"*Hasidic Community* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Comunidad jas\u00eddica* por Elyse Mertz Fotografias, 2021.",
    "src":"HasidicCommunity006.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":129,
    "site":"satmar-hasidic-community",
    "citation_en":"*Hasidic Community* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Comunidad jas\u00eddica* por Elyse Mertz Fotografias, 2021.",
    "src":"HasidicCommunity007.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":131,
    "site":"king-county-saving-bank",
    "citation_en":"*Exterior view of the Kings County Savings Bank located at the intersection of Bedford Avenue and Broadway.* Photographer and year unknown. Courtesy of Brooklyn Daily Eagle photographs, Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Vista exterior del Kings County Savings Bank ubicado en la intersecci\u00f3n de Bedford Avenue y Broadway.* Fot\u00f3grafo y a\u00f1o desconocido. Cortes\u00eda de fotograf\u00edas del Brooklyn Daily Eagle, Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"KingsCountySavingsBank001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":132,
    "site":"king-county-saving-bank",
    "citation_en":"*Kings County Savings Bank* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Banco de Ahorros del Condado de Kings* por Elyse Mertz Fotografias, 2021. ",
    "src":"KingsCountySavingsBank002.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":133,
    "site":"king-county-saving-bank",
    "citation_en":"*Kings County Savings Bank* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Banco de Ahorros del Condado de Kings* por Elyse Mertz Fotografias, 2021. ",
    "src":"KingsCountySavingsBank003.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":134,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Exterior view of the Feast of Our Lady of Mount Carmel, a street festival in Williamsburg, 1989.* Photograph by Anders Goldfarb. Courtesy of the Anders Goldfarb Photograph Collection, Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Vista exterior de la Fiesta de Nuestra Se\u00f1ora del Monte Carmelo, un festival callejero en Williamsburg, 1989.* Fotograf\u00eda de Anders Goldfarb. Cortes\u00eda de la Colecci\u00f3n de fotograf\u00edas de Anders Goldfarb, Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"FeastofOurLadyofCarmel001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":135,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Exterior, close-up view of several men carrying a statue of Giglio at the Feast of Our Lady of Carmel in Williamsburg,1989.* Photograph by Anders Goldfarb. Courtesy of the Anders Goldfarb Photograph Collection, Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Exterior, vista en primer plano de varios hombres cargando una estatua de Giglio en la Fiesta de Nuestra Se\u00f1ora del Carmelo en Williamsburg, 1989.* Fotograf\u00eda de Anders Goldfarb. Cortes\u00eda de la Colecci\u00f3n de fotograf\u00edas de Anders Goldfarb, Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"FeastofOurLadyofCarmel002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":136,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Exterior, close-up view of the statue of Giglio, atop a very tall pedestal, at the Feast of Our Lady of Carmel in Williamsburg, 1989.* Photograph by Anders Goldfarb. Courtesy of the Anders Goldfarb Photograph Collection, Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*Vista exterior, de cerca de la estatua de Giglio, sobre un pedestal muy alto, en la Fiesta de Nuestra Se\u00f1ora del Carmelo en Williamsburg, 1989.* Fotograf\u00eda de Anders Goldfarb. Cortes\u00eda de la Colecci\u00f3n de fotograf\u00edas de Anders Goldfarb, Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"FeastofOurLadyofCarmel003.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":137,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel004.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":138,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel005.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":139,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel006.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":140,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel007.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":141,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel008.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":142,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel009.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":143,
    "site":"our-lady-of-mount-carmel",
    "citation_en":"*Annual Giglio Procession and Feast of Our Lady of Mount Carmel in Williamsburg, Brooklyn.* Photograph by Danielle Russo, 2021.",
    "citation_sp":"*Procesi\u00f3n anual de Giglio y fiesta de Nuestra Se\u00f1ora del Monte Carmelo en Williamsburg, Brooklyn.* Fotograf\u00eda de Danielle Russo, 2021.",
    "src":"FeastofOurLadyofCarmel010.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":144,
    "site":"154-north-7-ave",
    "citation_en":"*154 North 7th Street* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*154 North 7th Street* por Elyse Mertz Fotografias, 2021.",
    "src":"154North7thStreet001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":145,
    "site":"34-berry-street",
    "citation_en":"*34 Berry Street* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*34 Berry Street* por Elyse Mertz Fotografias, 2021.",
    "src":"34BerryStreet001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":146,
    "site":"34-berry-street",
    "citation_en":"*34 Berry Street* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*34 Berry Street* por Elyse Mertz Fotografias, 2021.",
    "src":"34BerryStreet002.jpg",
    "categories":[
      "contaimnated-sites"
    ]
  },
  {
    "id":147,
    "site":"radiac-research-corp",
    "citation_en":null,
    "citation_sp":null,
    "src":"261KentAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":148,
    "site":"500-kent-ave",
    "citation_en":"*500 Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*500 Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"500Avenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":149,
    "site":"500-kent-ave",
    "citation_en":"*500 Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*500 Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"500Avenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":150,
    "site":"500-kent-ave",
    "citation_en":"*500 Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*500 Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"500Avenue003.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":151,
    "site":"100-south-4-street",
    "citation_en":"*100 S 4th Street* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*100 S 4th Street* por Elyse Mertz Fotografias, 2021.",
    "src":"100S4thStreet001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":152,
    "site":"100-south-4-street",
    "citation_en":"*100 S 4th Street* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*100 S 4th Street* por Elyse Mertz Fotografias, 2021.",
    "src":"100S4thStreet002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":153,
    "site":"williamsburg-bridgeview-apt",
    "citation_en":"*Williamsburg Bridgeview Apartments* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Apartamentos en Williamsburg Bridgeview* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgHouses001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":154,
    "site":"williamsburg-bridgeview-apt",
    "citation_en":"*Williamsburg Bridgeview Apartments* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Apartamentos en Williamsburg Bridgeview* por Elyse Mertz Fotografias, 2021.",
    "src":"WilliamsburgHouses002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":155,
    "site":"chromium-plating-polishing",
    "citation_en":"*373 Wythe Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*373 Wythe Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"373WytheAvenue001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":156,
    "site":"chromium-plating-polishing",
    "citation_en":"*373 Wythe Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*373 Wythe Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"373WytheAvenue002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":157,
    "site":"chromium-plating-polishing",
    "citation_en":"*373 Wythe Avenue* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*373 Wythe Avenue* por Elyse Mertz Fotografias, 2021.",
    "src":"373WytheAvenue003.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":158,
    "site":"newtown-creek",
    "citation_en":"*Portion of Newtown Creek at Meeker Avenue with a portion of bridge or pier and several houses and commercial buildings in the background.* Photographer and year unknown. Courtesy of Brooklyn Public Library, Brooklyn Collection. ",
    "citation_sp":"*Porci\u00f3n de Newtown Creek en Meeker Avenue con una porci\u00f3n de puente o muelle y varias casas y edificios comerciales al fondo.* Fot\u00f3grafo y a\u00f1o desconocido. Cortes\u00eda de Brooklyn Public Library, Brooklyn Collection.",
    "src":"NewtownCreek001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":161,
    "site":"newtown-creek",
    "citation_en":"*Tugboat with plume of smoke pushing barge on Newtown Creek in Greenpoint; sides of creek lined with barges and factory buildings, 1908.* Photographer unknown. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Remolcador con columna de humo empujando barcaza en Newtown Creek en Greenpoint; lados del arroyo bordeado de barcazas y edificios de f\u00e1bricas, 1908.* Fot\u00f3grafo desconocido. Cortes\u00eda de Brooklyn Public Library, Brooklyn Collection.",
    "src":"NewtownCreek002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":164,
    "site":"newtown-creek",
    "citation_en":"*Portion of Newtown Creek with sign-covered wharf on right and portion of commercial building in blurry left background. Photographer unknown, 1900.* Courtesy of Brooklyn Public Library, Brooklyn Collection. ",
    "citation_sp":"*Parte de Newtown Creek con un muelle cubierto de letreros a la derecha y una parte del edificio comercial con un fondo borroso a la izquierda.* Fot\u00f3grafo desconocido, 1900. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"NewtownCreek003.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":167,
    "site":"newtown-creek",
    "citation_en":"*View of a small house with a barn, outbuilding, and wagon in the foreground, on Newtown Creek, Brooklyn.* Photograph by A.R. Young, 1878. Courtesy of Center for Brooklyn History. ",
    "citation_sp":"*Vista de una peque\u00f1a casa con un granero, dependencia y vag\u00f3n en primer plano, en Newtown Creek, Brooklyn.* Fotograf\u00eda de A.R. Young, 1878. Cortes\u00eda del Center for Brooklyn History.",
    "src":"NewtownCreek004.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":170,
    "site":"newtown-creek",
    "citation_en":"*A clipping with a black and white aerial photograph of Newtown Creek showing the industrial growth on both sides of the creek. One of the World's Busiest Waterways. Newtown Creek, the dividing line between Brooklyn and Long Island City, carries an enormous tonnage. Its cargoes exceed those of the Mississippi River. This photograph gives an excellent view of the industrial development of both sides of the creek.* Photograph by Fairchild Aerial Camera Corporation, 1910. Courtesy of Center for Brooklyn History.",
    "citation_sp":"*Un recorte con una fotograf\u00eda a\u00e9rea en blanco y negro de Newtown Creek que muestra el crecimiento industrial a ambos lados del arroyo. Una de las v\u00edas fluviales m\u00e1s transitadas del mundo. Newtown Creek, la l\u00ednea divisoria entre Brooklyn y Long Island City, tiene un tonelaje enorme. Sus cargas superan a las del r\u00edo Mississippi. Esta fotograf\u00eda ofrece una excelente vista del desarrollo industrial de ambos lados del arroyo.* Fotograf\u00eda de Fairchild Aerial Camera Corporation, 1910. Cortes\u00eda del Center for Brooklyn History.",
    "src":"NewtownCreek005.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":173,
    "site":"newtown-creek",
    "citation_en":"*Newtown Creek on October 26, 1966.* Courtesy of NYC Municipal Archives and Department of Records & Information Services. ",
    "citation_sp":"*Newtown Creek el 26 de octubre de 1966.* Cortes\u00eda de NYC Municipal Archives y Department of Records & Information Services.",
    "src":"NewtownCreek006.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":176,
    "site":"newtown-creek",
    "citation_en":"*Newtown Creek *by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Cala Newtown* por Elyse Mertz Fotografias, 2021.",
    "src":"NewtownCreek007.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":179,
    "site":"newtown-creek",
    "citation_en":"*Newtown Creek *by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Cala Newtown* por Elyse Mertz Fotografias, 2021.",
    "src":"NewtownCreek008.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":182,
    "site":"newtown-creek",
    "citation_en":"*Newtown Creek *by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Cala Newtown* por Elyse Mertz Fotografias, 2021.",
    "src":"NewtownCreek009.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":185,
    "site":"slattery-gas-stove",
    "citation_en":"*Slattery Gas Stove* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Estufa de gas Slattery* por Elyse Mertz Fotografias, 2021.",
    "src":"SlatteryGasStove001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":186,
    "site":"slattery-gas-stove",
    "citation_en":"*Slattery Gas Stove* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Estufa de gas Slattery* por Elyse Mertz Fotografias, 2021.",
    "src":"SlatteryGasStove002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":188,
    "site":"b-qe",
    "citation_en":"*The picture gives a bird's eye view of the Meeker Ave. link with the Brooklyn Crosstown Highway [i.e. Brooklyn-Queens Expressway] that caused the demolition by WPA crews of 345 buildings.* Photographer unknown. Published in The Brooklyn Eagle, 1939. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*La imagen ofrece una vista de p\u00e1jaro del enlace de Meeker Ave. con la autopista Brooklyn Crosstown [es decir, Autopista Brooklyn-Queens] que caus\u00f3 la demolici\u00f3n por equipos de WPA de 345 edificios.* Fot\u00f3grafo desconocido. Publicado en The Brooklyn Eagle, 1939. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"BQE001.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":190,
    "site":"b-qe",
    "citation_en":"*Intersection of Lee Avenue and Keap Street in with Williamsburg Christian Church, a large brick building, on corner, several parked automobiles, one moving bus, several pedestrians, four row houses in left background, several apartment houses with shops on ground level in right background, and two moving automobiles on Brooklyn-Queens Expressway below.* Photograph by Irving I. Herzberg, 1965. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Intersecci\u00f3n de Lee Avenue y Keap Street con la iglesia Williamsburg Christian, un gran edificio de ladrillos, en la esquina, varios autom\u00f3viles estacionados, un autob\u00fas en movimiento, varios peatones, cuatro hileras de casas al fondo a la izquierda, varios edificios de apartamentos con tiendas en la planta baja en fondo derecho, y dos autom\u00f3viles en movimiento en Brooklyn-Queens Expressway abajo.* Fotograf\u00eda de Irving I. Herzberg, 1965. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n Brooklyn.",
    "src":"BQE002.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":194,
    "site":"public-school-71k",
    "citation_en":"*Public School 71K* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Escuela Publica 71K* de Elyse Mertz Fotografias, 2021.",
    "src":"PublicSchool71K001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":195,
    "site":"public-school-71k",
    "citation_en":"*Public School 71K* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Escuela Publica 71K* de Elyse Mertz Fotografias, 2021.",
    "src":"PublicSchool71K002.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":196,
    "site":"williamsburgh-saving-bank ",
    "citation_en":"*Williamsburg Savings Bank---The branch office of the Williamsburg Bank, Broadway and Driggs Ave., was selected for preservation by the Municipal Art Society as an outstanding example of the Baroque influence on architecture that came into great populatiry in the last half of the 19th century.* Published in The Brooklyn Eagle, 1954. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Banco de ahorros de Williamsburg : la sucursal del Williamsburg Bank, Broadway y Driggs Ave., fue seleccionada para su conservaci\u00f3n por la Municipal Art Society como un ejemplo sobresaliente de la influencia barroca en la arquitectura que alcanz\u00f3 gran popularidad en la \u00faltima mitad del siglo XIX. Publicado en The Brooklyn Eagle, 1954.* Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n de Brooklyn.",
    "src":"WilliamsburgSavingsBank001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":197,
    "site":"williamsburgh-saving-bank ",
    "citation_en":null,
    "citation_sp":null,
    "src":"WilliamsburgSavingsBank002.jpg",
    "categories":[
      null
    ]
  },
  {
    "id":198,
    "site":"williamsburgh-saving-bank ",
    "citation_en":null,
    "citation_sp":null,
    "src":"WilliamsburgSavingsBank003.jpg",
    "categories":[
      null
    ]
  },
  {
    "id":199,
    "site":"peter-luger",
    "citation_en":"*Peter Luger* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Peter Luger* por Elyse Mertz Fotografias, 2021.",
    "src":"PeterLuger001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":200,
    "site":"peter-luger",
    "citation_en":"*Peter Luger* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Peter Luger* por Elyse Mertz Fotografias, 2021.",
    "src":"PeterLuger002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":201,
    "site":"gottliebs-restaurant",
    "citation_en":"*An exterior view of Gottlieb's Restaurant, a kosher deli located at 352 Roebling Street in the Williamsburg neighborhood of Brooklyn. The storefront has been open since 1962, serving primarily Eastern European staples. This photograph is part of an ongoing project by photographers James and Karla Murray to document storefronts in Brooklyn and New York City.* Photography by Karla and James Murray, 2006. Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Una vista exterior del restaurante Gottlieb's, un deli kosher ubicado en 352 Roebling Street en el vecindario de Williamsburg en Brooklyn. La tienda ha estado abierta desde 1962 y sirve principalmente productos b\u00e1sicos de Europa del Este. Esta fotograf\u00eda es parte de un proyecto en curso de los fot\u00f3grafos James y Karla Murray para documentar escaparates en Brooklyn y la ciudad de Nueva York.* Fotograf\u00eda de Karla y James Murray, 2006. Cortes\u00eda de NYC Municipal Archives y Department of Records & Information Services.",
    "src":"Gottlieb'sRestaurant001.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":202,
    "site":"gottliebs-restaurant",
    "citation_en":"*Gottlieb's Restaurant *by Elyse Mertz Photography, 2021.",
    "citation_sp":" *Restaurante Gottlieb* por Elyse Mertz Fotografias, 2021.",
    "src":"Gottlieb'sRestaurant002.jpg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":203,
    "site":"williamsburg-bridge",
    "citation_en":"*Sonny Rollins plays his saxophone on the Williamsburg Bridge.* Photographer David McLane. Published in New York Daily News, 1996. New York Daily News Archives via Getty Images.",
    "citation_sp":"*Sonny Rollins toca su saxof\u00f3n en el puente de Williamsburg.* Fot\u00f3grafo David McLane. Publicado en New York Daily News, 1996. Archivos de New York Daily News a trav\u00e9s de Getty Images.",
    "src":"SonnyRollins001.jpeg",
    "categories":[
      "unmarked-histories"
    ]
  },
  {
    "id":206,
    "site":"continental-army",
    "citation_en":"*Continential Army Plaza* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Plaza Ej\u00e9rcito Continental* por Elyse Mertz Fotografias, 2021.",
    "src":"ContinentialArmyPlaza001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":207,
    "site":"continental-army",
    "citation_en":"*Continential Army Plaza* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Plaza Ej\u00e9rcito Continental* por Elyse Mertz Fotografias, 2021.",
    "src":"ContinentialArmyPlaza002.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":208,
    "site":"domino-sugar",
    "citation_en":"*Puerto Rican sugar---The unloading of cargoes from ports all over the face of the globe extends along the Brooklyn waterfronts from Bay Ridge to Williamsburg and beyond. At the American Sugar Refining Company receiving wharf at the foot of S. 2d St., 35,000 bags, or 4,000 tons, of raw sugar is delivered directly to the Domino refinery.* Photographer unknown. Published in The Brooklyn Eagle, 1951. Courtesy of Brooklyn Public Library, Brooklyn Collection.",
    "citation_sp":"*Az\u00facar puertorrique\u00f1a: la descarga de cargamentos de puertos de todo el mundo se extiende a lo largo de los muelles de Brooklyn desde Bay Ridge hasta Williamsburg y m\u00e1s all\u00e1. En el muelle receptor de American Sugar Refining Company al pie de S. 2d St., se entregan 35.000 sacos o 4.000 toneladas de az\u00facar en bruto directamente a la refiner\u00eda de Domino.* Fot\u00f3grafo desconocido. Publicado en The Brooklyn Eagle, 1951. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Colecci\u00f3n Brooklyn.",
    "src":"DominoSugarRefinery001.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":210,
    "site":"domino-sugar",
    "citation_en":"*American Sugar-Refining Company, Havemeyer and Brooklyn Houses. The company owned refineries located in Brooklyn, and had offices at Wall and South Streets in Manhattan.* Photographer unknown, circa 1890. Courtesy of Brooklyn Public Library, Center for Brooklyn History. ",
    "citation_sp":"*The American Sugar Refining Company, ubicada en Kent Avenue entre las calles South First y South Second. Postal de Brooklyn Eagle, serie 59, n\u00famero 349.* Fot\u00f3grafo desconocido, alrededor de 1910. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"DominoSugarRefinery002.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":212,
    "site":"domino-sugar",
    "citation_en":"*The American Sugar Refining Company, located at Kent Avenue between South First and South Second Streets. Brooklyn Eagle postcard, series 59, number 349.* Photographer unknown, circa 1910. Courtesy of Brooklyn Public Library, Center for Brooklyn History.",
    "citation_sp":"*The American Sugar Refining Company, ubicada en Kent Avenue entre las calles South First y South Second. Postal de Brooklyn Eagle, serie 59, n\u00famero 349.* Fot\u00f3grafo desconocido, alrededor de 1910. Cortes\u00eda de la Biblioteca P\u00fablica de Brooklyn, Centro de Historia de Brooklyn.",
    "src":"DominoSugarRefinery003.jpg",
    "categories":[
      "historic-landmarks"
    ]
  },
  {
    "id":214,
    "site":"domino-sugar",
    "citation_en":"*Domino Sugar Refinery* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Refiner\u00eda de az\u00facar Domino* por Elyse Mertz Fotografias, 2021.",
    "src":"DominoSugarRefinery004.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":216,
    "site":"domino-sugar",
    "citation_en":"*Domino Sugar Refinery* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Refiner\u00eda de az\u00facar Domino* por Elyse Mertz Fotografias, 2021.",
    "src":"DominoSugarRefinery005.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":218,
    "site":"domino-sugar",
    "citation_en":"*Domino Sugar Refinery* by Elyse Mertz Photography, 2021.",
    "citation_sp":"*Refiner\u00eda de az\u00facar Domino* por Elyse Mertz Fotografias, 2021.",
    "src":"DominoSugarRefinery006.jpg",
    "categories":[
      "contaminated-sites"
    ]
  },
  {
    "id":227,
    "site":"b-qe",
    "citation_en":"*Brooklyn-Queens Expressway at Kent & Bedford Avenues in Williamsburgh, Brooklyn. Photograph by Thomas Skyviews oono October 31, 1964.* Courtesy of NYC Municipal Archives and Department of Records & Information Services.",
    "citation_sp":"*Autopista Brooklyn-Queens en las avenidas Kent y Bedford en Williamsburgh, Brooklyn. Fotograf\u00eda de Thomas Skyviews oono el 31 de octubre de 1964.* Cortes\u00eda de los Archivos Municipales de la Ciudad de Nueva York y del Departamento de Servicios de Informaci\u00f3n y Registros.",
    "src":"BQE003.jpg",
    "categories":[
      "historic-landmarks"
    ]
  }
]