import React, { useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import { tailwind } from 'styles/tailwind'
import { StyledText } from './StyledText'
import { MapLayer } from 'types/map'
import { LayerToggle } from './LayerToggle'
import { CATEGORIES, BASE_LAYERS } from 'config'
import { data } from 'db'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { AppContext } from 'appState'
import { makeCategoryFromSlug } from 'providers/CategoriesProvider'
import translations from 'content/translations'
import { Language } from 'types/language'

export const MapDrawer: React.FC<{
  filterLayersMenu: boolean
  setFilterLayersMenu: (b: boolean) => void
  activeLayers: string[]
  baseLayerMenu: boolean
  setBaseLayerMenu: (b: boolean) => void
  baseLayer: string
  setBaseLayer: (layerSlug: string) => void
  toggleLayer: (layerSlug: string) => void
  language: Language
}> = ({
  filterLayersMenu,
  setFilterLayersMenu,
  activeLayers,
  baseLayerMenu,
  setBaseLayerMenu,
  baseLayer,
  setBaseLayer,
  toggleLayer,
  language
}) => {
  return (
    <View
      style={{
        ...tailwind('w-full absolute bottom-0'),
        top: filterLayersMenu
          ? 'auto'
          : Dimensions.get('window').height - (baseLayerMenu ? 250 : 140)
      }}>
      {baseLayerMenu && (
        <View
          style={{
            ...tailwind(
              'relative bg-white h-40 w-full rounded-full p-5 pr-10 flex flex-row flex-wrap'
            ),
            top: 0,
            opacity: baseLayerMenu ? 1 : 0
          }}>
          {BASE_LAYERS.map((l) => (
            <TouchableOpacity
              style={tailwind(
                `${
                  l === baseLayer ? 'bg-black' : 'bg-gray-200'
                } mr-2 mb-2 px-4 py-2 rounded-full`
              )}
              key={l}
              onPress={() => {
                if (l != baseLayer) {
                  setBaseLayer(l)
                } else {
                  setBaseLayer('')
                }
              }}>
              <StyledText
                el="sm"
                c={l === baseLayer ? '#fff' : '#000'}
                style={{ textTransform: 'uppercase', letterSpacing: 0.5 }}>
                {data.overlayLayers.find((dl) => dl.slug === l).title}
              </StyledText>
            </TouchableOpacity>
          ))}
          <TouchableOpacity
            style={tailwind(
              'absolute mt-10 right-0 w-2/12 flex-shrink-0 flex flex-row items-center justify-end rounded-l-full bg-black h-12 w-12'
            )}>
            <Icon
              style={tailwind('p-4')}
              name="close"
              size={16}
              color="#fff"
              onPress={() => setBaseLayerMenu(!baseLayerMenu)}
            />
          </TouchableOpacity>
        </View>
      )}
      <View style={tailwind('w-full flex flex-row justify-end pb-4')}>
        {!baseLayerMenu && (
          <TouchableOpacity onPress={() => setBaseLayerMenu(!baseLayerMenu)}>
            <View
              style={{
                ...tailwind(
                  'bg-black rounded-full px-8 py-4 mr-4 items-center flex flex-row'
                )
              }}>
              <Icon
                style={tailwind('pr-2')}
                name="layers-outline"
                size={16}
                color="#fff"
              />
              <StyledText el="btn">
                {translations['Climate Timeline'][language]}
              </StyledText>
            </View>
          </TouchableOpacity>
        )}
      </View>
      <View style={tailwind('bg-white px-8 pb-10 rounded-t-full')}>
        <TouchableOpacity
          style={tailwind('h-16 justify-center items-center w-full')}
          onPress={() => setFilterLayersMenu(!filterLayersMenu)}>
          <Icon
            name={filterLayersMenu ? 'chevron-down' : 'chevron-up'}
            size={16}
            color="#000"
          />
        </TouchableOpacity>
        {!filterLayersMenu && <View style={tailwind('h-24')}></View>}
        <View style={tailwind('flex flex-row justify-between mb-6')}>
          <StyledText el="map-key">
            {translations['Map Key'][language]}
          </StyledText>
          <StyledText el="map-key">
            {translations['ON/OFF'][language]}
          </StyledText>
        </View>
        {CATEGORIES.map((category) => (
          <LayerToggle
            key={category}
            category={makeCategoryFromSlug(category)}
            language={language}
            on={activeLayers.includes(category)}
            toggle={toggleLayer}
          />
        ))}
      </View>
    </View>
  )
}
