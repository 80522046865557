export default [
  {
    "id":1.0,
    "slug":"gowanus-expressway",
    "title":"Gowanus Expressway",
    "address":"Gowanus Expressway",
    "latitude":40.6726768,
    "longitude":-73.9995045,
    "latLng":"40.6726768,-73.9995045"
  },
  {
    "id":2.0,
    "slug":"red-hook-stores",
    "title":"Red Hook Stores",
    "address":"480-500 Van Brunt Street",
    "latitude":40.6743854,
    "longitude":-74.0169653,
    "latLng":"40.6743854,-74.0169653"
  },
  {
    "id":3.0,
    "slug":"valentino-park",
    "title":"Valentino Park ",
    "address":"Ferris x Coffey Street",
    "latitude":40.678131,
    "longitude":-74.01689,
    "latLng":"40.678131,-74.01689"
  },
  {
    "id":4.0,
    "slug":"chesebrough-site",
    "title":"Chesebrough Site",
    "address":"44 Ferris Street, 219 Sullivan Street, 236 Wolcott Street",
    "latitude":40.680342,
    "longitude":-74.016428,
    "latLng":"40.680342,-74.016428"
  },
  {
    "id":5.0,
    "slug":"fort-defiance",
    "title":"Fort Defiance",
    "address":null,
    "latitude":40.67655,
    "longitude":-74.015972,
    "latLng":"40.67655,-74.015972"
  },
  {
    "id":6.0,
    "slug":"erie-basin",
    "title":"Erie Basin",
    "address":null,
    "latitude":40.669299,
    "longitude":-74.01546,
    "latLng":"40.669299,-74.01546"
  },
  {
    "id":7.0,
    "slug":"ny-dock-company",
    "title":"NY Dock Company",
    "address":"42 Ferris Street",
    "latitude":40.680639,
    "longitude":-74.015259,
    "latLng":"40.680639,-74.015259"
  },
  {
    "id":8.0,
    "slug":"dikeman-and-conover",
    "title":"Dikeman and Conover",
    "address":null,
    "latitude":40.675603,
    "longitude":-74.014179,
    "latLng":"40.675603,-74.014179"
  },
  {
    "id":9.0,
    "slug":"van-brunt-and-coffey",
    "title":"Van Brunt and Coffey",
    "address":"Van Brunt x Coffey Street",
    "latitude":40.675603,
    "longitude":-74.014179,
    "latLng":"40.675603,-74.014179"
  },
  {
    "id":10.0,
    "slug":"brooklyn-clay-retort",
    "title":"Brooklyn Clay Retort",
    "address":"419 Van Brunt Street",
    "latitude":40.675247,
    "longitude":-74.014114,
    "latLng":"40.675247,-74.014114"
  },
  {
    "id":11.0,
    "slug":"fire-bricks-works-storehouse",
    "title":"Fire Brick Works Storehouse",
    "address":"76-86 Van Dyke Street",
    "latitude":40.6749467,
    "longitude":-74.0133311,
    "latLng":"40.6749467,-74.0133311"
  },
  {
    "id":12.0,
    "slug":"erie-basin-coal-yard",
    "title":"Erie Basin Coal Yard",
    "address":"225 Richards Street",
    "latitude":40.673887,
    "longitude":-74.013073,
    "latLng":"40.673887,-74.013073"
  },
  {
    "id":13.0,
    "slug":"house-of-thomas-mcnulty",
    "title":"House of Thomas McNulty",
    "address":"Dikeman x Richards Street",
    "latitude":40.675891,
    "longitude":-74.012076,
    "latLng":"40.675891,-74.012076"
  },
  {
    "id":14.0,
    "slug":"jazmin-nazario",
    "title":"Jazmin Nazario",
    "address":"P.S. 15 Patrick F. Daly, 71 Sullivan Street",
    "latitude":40.6769334,
    "longitude":-74.0119523,
    "latLng":"40.6769334,-74.0119523"
  },
  {
    "id":15.0,
    "slug":"karbo-bronze",
    "title":"Karbo Bronze",
    "address":"1 Van Dyke Street",
    "latitude":40.673604,
    "longitude":-74.011017,
    "latLng":"40.673604,-74.011017"
  },
  {
    "id":16.0,
    "slug":"slave-ship-erie-in-atlantic-basin",
    "title":"Slave Ship \"Erie\" \/ Atlantic Basin",
    "address":null,
    "latitude":40.683342,
    "longitude":-74.010947,
    "latLng":"40.683342,-74.010947"
  },
  {
    "id":17.0,
    "slug":"brookhattan-smelting-refining-company",
    "title":"Brookhattan Smelting & Refining Company",
    "address":"162 Richards Street",
    "latitude":40.6770078,
    "longitude":-74.0107347,
    "latLng":"40.6770078,-74.0107347"
  },
  {
    "id":18.0,
    "slug":"revere-sugar",
    "title":"Revere Sugar",
    "address":"270 - 280 Richards Street",
    "latitude":40.6710864,
    "longitude":-74.0132773,
    "latLng":"40.6710864,-74.0132773"
  },
  {
    "id":20.0,
    "slug":"red-hook-community-farms",
    "title":"Red Hook Community Farms",
    "address":"560 Columbia Street",
    "latitude":40.67264,
    "longitude":-74.00928,
    "latLng":"40.67264,-74.00928"
  },
  {
    "id":21.0,
    "slug":"red-hook-community-justice-center",
    "title":"Red Hook Community Justice Center",
    "address":"88 Visitation Place",
    "latitude":40.6791803,
    "longitude":-74.0094926,
    "latLng":"40.6791803,-74.0094926"
  },
  {
    "id":22.0,
    "slug":"640-columbia-street",
    "title":"640 Columbia Street",
    "address":"640 Columbia Street",
    "latitude":40.671064,
    "longitude":-74.008976,
    "latLng":"40.671064,-74.008976"
  },
  {
    "id":23.0,
    "slug":"akem-le-gendre",
    "title":"Akem Le Gendre",
    "address":"Coffey Park",
    "latitude":40.67779,
    "longitude":-74.008423,
    "latLng":"40.67779,-74.008423"
  },
  {
    "id":24.0,
    "slug":"isis-ivey",
    "title":"Isis Ivey",
    "address":"Coffey Park Dwight Street Entrace",
    "latitude":40.67695,
    "longitude":-74.008092,
    "latLng":"40.67695,-74.008092"
  },
  {
    "id":25.0,
    "slug":"red-hook-grain-terminal",
    "title":"Red Hook Grain Terminal",
    "address":"685 Columbia Street",
    "latitude":40.6692964,
    "longitude":-74.0064547,
    "latLng":"40.6692964,-74.0064547"
  },
  {
    "id":26.0,
    "slug":"devin-dennis",
    "title":"Devin Dennis",
    "address":"Columbia x Huntington Street",
    "latitude":40.677838,
    "longitude":-74.006157,
    "latLng":"40.677838,-74.006157"
  },
  {
    "id":27.0,
    "slug":"nelson-street",
    "title":"Nelson Street",
    "address":"Nelson Street",
    "latitude":40.678521,
    "longitude":-74.005121,
    "latLng":"40.678521,-74.005121"
  },
  {
    "id":28.0,
    "slug":"iris-ivey",
    "title":"Iris Ivey",
    "address":"797 Hicks Street",
    "latitude":40.6756276,
    "longitude":-74.0050755,
    "latLng":"40.6756276,-74.0050755"
  },
  {
    "id":29.0,
    "slug":"red-hook-ballfields",
    "title":"Red Hook Ballfields \/ Columbia Smelting & Refining Works",
    "address":"Lorraine x Hicks Street",
    "latitude":40.6723421,
    "longitude":-74.0049932,
    "latLng":"40.6723421,-74.0049932"
  },
  {
    "id":30.0,
    "slug":"luquer-street",
    "title":"Luquer Street",
    "address":"18 Luquer Street, 26 Luquer Street, 28 Luquer Street & 24 Luquer Street",
    "latitude":40.678656,
    "longitude":-74.004736,
    "latLng":"40.678656,-74.004736"
  },
  {
    "id":31.0,
    "slug":"red-hook-houses",
    "title":"Red Hook Houses",
    "address":"62 Mill Street & 55 Dwight Street",
    "latitude":40.675838,
    "longitude":-74.004608,
    "latLng":"40.675838,-74.004608"
  },
  {
    "id":32.0,
    "slug":"jholdy-cruz",
    "title":"Jholdy Cruz",
    "address":"767 Hicks Street",
    "latitude":40.6767364,
    "longitude":-74.004486,
    "latLng":"40.6767364,-74.004486"
  },
  {
    "id":33.0,
    "slug":"red-hook-cruise-ship-terminal",
    "title":"Red Hook Cruise Ship Terminal",
    "address":"72 Bowne Street",
    "latitude":40.676701,
    "longitude":-74.004463,
    "latLng":"40.676701,-74.004463"
  },
  {
    "id":34.0,
    "slug":"red-hook-initiative",
    "title":"Red Hook Initiative",
    "address":"767 Hicks Street",
    "latitude":40.676701,
    "longitude":-74.004463,
    "latLng":"40.676701,-74.004463"
  },
  {
    "id":35.0,
    "slug":"redemption-church",
    "title":"Redemption Church",
    "address":"767 Hicks Street",
    "latitude":40.6767,
    "longitude":-74.00446,
    "latLng":"40.6767,-74.00446"
  },
  {
    "id":36.0,
    "slug":"batmobile-garage",
    "title":"Batmobile Garage",
    "address":"72 Huntington Street",
    "latitude":40.6769943,
    "longitude":-74.0034784,
    "latLng":"40.6769943,-74.0034784"
  },
  {
    "id":37.0,
    "slug":"tin-city-hooverville",
    "title":"\"Tin City\" \/ Hooverville",
    "address":"155 Bay Street",
    "latitude":40.671972,
    "longitude":-74.003357,
    "latLng":"40.671972,-74.003357"
  },
  {
    "id":38.0,
    "slug":"coffey-and-ostego",
    "title":"Coffey and Ostego",
    "address":"151 Dwight Street, 147 Dwight Street, 143 Dwight Street, 40 Otsego Street, 60 Otsego Street",
    "latitude":40.669227,
    "longitude":-74.003195,
    "latLng":"40.669227,-74.003195"
  },
  {
    "id":39.0,
    "slug":"red-hook-conservancy",
    "title":"Red Hook Conservancy",
    "address":"155 Bay Street",
    "latitude":40.672223,
    "longitude":-74.0043055,
    "latLng":"40.672223,-74.0043055"
  },
  {
    "id":40.0,
    "slug":"red-hook-recreation-center-pool",
    "title":"Red Hook Recreation Center & Pool",
    "address":"151 Bay Street",
    "latitude":40.67169,
    "longitude":-74.00243,
    "latLng":"40.67169,-74.00243"
  },
  {
    "id":41.0,
    "slug":"smith-street",
    "title":"Smith Street",
    "address":"659 Smith Street, 627 Smith Street & 611 Smith Street",
    "latitude":40.669179,
    "longitude":-74.000452,
    "latLng":"40.669179,-74.000452"
  },
  {
    "id":42.0,
    "slug":"gowanus-canal",
    "title":"Gowanus Canal",
    "address":null,
    "latitude":40.6698541,
    "longitude":-73.9991722,
    "latLng":"40.6698541,-73.9991722"
  },
  {
    "id":43.0,
    "slug":"red-hook-lane",
    "title":"Red Hook Lane",
    "address":"Red Hook Lane",
    "latitude":40.6910784,
    "longitude":-73.9888335,
    "latLng":"40.6910784,-73.9888335"
  },
  {
    "id":44.0,
    "slug":"blue-city",
    "title":"Blue City",
    "address":"Gowanus Bay Terminal",
    "latitude":40.6679409,
    "longitude":-74.0098608,
    "latLng":"40.6679409,-74.0098608"
  },
  {
    "id":45.0,
    "slug":"todd-shipyard",
    "title":"Todd Shipyard",
    "address":"1 Beard Street",
    "latitude":40.671774,
    "longitude":-74.0112733,
    "latLng":"40.671774,-74.0112733"
  },
  {
    "id":46.0,
    "slug":"reti-center",
    "title":"RETI Center",
    "address":"153 Coffey Street",
    "latitude":40.67928,
    "longitude":-74.00942,
    "latLng":"40.67928,-74.00942"
  },
  {
    "id":47.0,
    "slug":"citi-bike-47",
    "title":"CitiBike",
    "address":"Coffey x Conover Street",
    "latitude":40.6772612661,
    "longitude":-74.0153996945,
    "latLng":"40.6772612661487,-74.0153996944911"
  },
  {
    "id":48.0,
    "slug":"citi-bike-48",
    "title":"Citi Bike",
    "address":"Van Brunt x Van Dyke Street",
    "latitude":40.6759512543,
    "longitude":-74.014807222,
    "latLng":"40.675951254337,-74.0148072220363"
  },
  {
    "id":49.0,
    "slug":"citi-bike-49",
    "title":"Citi Bike",
    "address":"Van Brunt x Wolcott Street",
    "latitude":40.6775211026,
    "longitude":-74.012953852,
    "latLng":"40.6775211025521,-74.012953852007"
  },
  {
    "id":50.0,
    "slug":"citi-bike-50",
    "title":"Citi Bike",
    "address":"Pioneer x Van Brunt Street",
    "latitude":40.679104161,
    "longitude":-74.0110387656,
    "latLng":"40.6791041609992,-74.011038765591"
  },
  {
    "id":51.0,
    "slug":"citi-bike-51",
    "title":"Citi Bike",
    "address":"Pioneer x Richards Street",
    "latitude":40.6780846637,
    "longitude":-74.0095362656,
    "latLng":"40.6780846637441,-74.0095362655948"
  },
  {
    "id":52.0,
    "slug":"citi-bike-52",
    "title":"Citi Bike",
    "address":"Columbia x W 9 Street",
    "latitude":40.6773952654,
    "longitude":-74.0065268367,
    "latLng":"40.6773952653956,-74.0065268366935"
  },
  {
    "id":53.0,
    "slug":"citi-bike-53",
    "title":"Citi Bike",
    "address":"Commerce x Van Brunt Street",
    "latitude":40.6812695008,
    "longitude":-74.0085744512,
    "latLng":"40.6812695007605,-74.0085744512034"
  },
  {
    "id":54.0,
    "slug":"citi-bike-54",
    "title":"Citi Bike",
    "address":"Richards x Delavan Street",
    "latitude":40.6797261049,
    "longitude":-74.0076586356,
    "latLng":"40.6797261048564,-74.0076586356098"
  },
  {
    "id":55.0,
    "slug":"citi-bike-55",
    "title":"Citi Bike",
    "address":"Wolcott x Dwight Street",
    "latitude":40.675471434,
    "longitude":-74.0099251948,
    "latLng":"40.6754714340209,-74.0099251947524"
  },
  {
    "id":56.0,
    "slug":"citi-bike-56",
    "title":"Citi Bike",
    "address":"Dwight x Van Dyke Street",
    "latitude":40.6738891491,
    "longitude":-74.0117745362,
    "latLng":"40.6738891490821,-74.0117745361566"
  },
  {
    "id":57.0,
    "slug":"citi-bike-57",
    "title":"Citi Bike",
    "address":"Columbia x Lorraine Street",
    "latitude":40.6748781949,
    "longitude":-74.0077687218,
    "latLng":"40.674878194895,-74.0077687217693"
  },
  {
    "id":58.0,
    "slug":"citi-bike-58",
    "title":"Citi Bike",
    "address":"Henry x W 9 Street",
    "latitude":40.6764598051,
    "longitude":-74.0031928942,
    "latLng":"40.6764598051391,-74.0031928942138"
  },
  {
    "id":59.0,
    "slug":"citi-bike-59",
    "title":"Citi Bike",
    "address":"Sigourney x Columbia Street",
    "latitude":40.6728590902,
    "longitude":-74.0086538515,
    "latLng":"40.6728590901707,-74.0086538514726"
  },
  {
    "id":60.0,
    "slug":"citi-bike-60",
    "title":"Citi Bike",
    "address":"Henry x Bay Street",
    "latitude":40.6725403532,
    "longitude":-74.0050792651,
    "latLng":"40.6725403532405,-74.0050792650546"
  },
  {
    "id":61.0,
    "slug":"citi-bike-61",
    "title":"Citi Bike",
    "address":"Clinton x Centre Street",
    "latitude":40.6746820585,
    "longitude":-74.001941322,
    "latLng":"40.6746820585124,-74.0019413220365"
  },
  {
    "id":62.0,
    "slug":"lidgerwood-manufacturing",
    "title":"Lidgerwood Manufacturing",
    "address":"84 Ferris Street",
    "latitude":40.6787106,
    "longitude":-74.0169371,
    "latLng":"40.6787106,-74.0169371"
  },
  {
    "id":63.0,
    "slug":"jakai-lowe",
    "title":"Jakai Lowe",
    "address":"626 Clinton Street",
    "latitude":40.674173,
    "longitude":-74.002496,
    "latLng":"40.674173,-74.002496"
  },
  {
    "id":64.0,
    "slug":"red-hook-art-project",
    "title":"30 Wolcott Street Farm",
    "address":"22 Commerce Street",
    "latitude":40.6797899683,
    "longitude":-74.0062312255,
    "latLng":"40.6797899683021,-74.0062312255222"
  },
  {
    "id":65.0,
    "slug":"what-is-superstorm-sandy",
    "title":"What is Superstorm Sandy?",
    "address":"Fairway Market at 480 Van Brunt St, 11231",
    "latitude":40.6743028182,
    "longitude":-74.016989415,
    "latLng":"40.6743028181657,-74.0169894150062"
  },
  {
    "id":66.0,
    "slug":"what-is-an-urban-heat-island",
    "title":"What is an urban heat island?",
    "address":"Red Hook Houses East at 62 Mill Street, 11231",
    "latitude":40.675984322,
    "longitude":-74.0046720267,
    "latLng":"40.6759843220074,-74.0046720266546"
  },
  {
    "id":67.0,
    "slug":"what-is-the-relationship-between-covid",
    "title":"What is the relationship between COVID-19, climate change, and climate inequality?",
    "address":"Red Hook Houses East at 62 Mill Street 11231",
    "latitude":40.675984322,
    "longitude":-74.0046720267,
    "latLng":"40.6759843220074,-74.0046720266546"
  },
  {
    "id":79.0,
    "slug":"what-is-the-relationship-between-covid",
    "title":"What is the relationship between COVID-19, climate change, and climate inequality?",
    "address":"Red Hook Houses West 55 Dwight Street 11231",
    "latitude":40.6767018291,
    "longitude":-74.0079699843,
    "latLng":"40.6767018290723,-74.0079699843248"
  },
  {
    "id":68.0,
    "slug":"what-is-air-pollution",
    "title":"What is air pollution?",
    "address":"Brooklyn Cruise Terminal at 72 Bowne St, Brooklyn, NY 11231",
    "latitude":40.6824702,
    "longitude":-74.0075293,
    "latLng":"40.6824702,-74.0075293"
  },
  {
    "id":78.0,
    "slug":"what-is-air-pollution",
    "title":"What is air pollution?",
    "address":"Gowanus Expressway",
    "latitude":40.6450878415,
    "longitude":-74.0174227727,
    "latLng":"40.6450878414923,-74.0174227726771"
  },
  {
    "id":69.0,
    "slug":"who-were-the-carnarsee-of-lenapehoking",
    "title":"Who were the Carnarsee of Lenapehoking? What was Sassian?",
    "address":"404 Van Brunt St, Brooklyn, NY 11231",
    "latitude":40.6766558144,
    "longitude":-74.0143649034,
    "latLng":"40.6766558144064,-74.0143649033578"
  },
  {
    "id":70.0,
    "slug":"how-did-colonialism-affect-the-brooklyn-waterfront",
    "title":"How did colonialism affect the Brooklyn waterfront?",
    "address":"Lucky Star Bus at 699 Columbia Street, 11231",
    "latitude":40.6671516,
    "longitude":-74.0106051,
    "latLng":"40.6671516,-74.0106051"
  },
  {
    "id":71.0,
    "slug":"what-is-the-history-of-slavery-and-black-labor",
    "title":"What is the history of slavery and Black labor on the Brooklyn waterfront?",
    "address":"Erie Basin Park",
    "latitude":40.6712240282,
    "longitude":-74.0130111034,
    "latLng":"40.6712240282455,-74.0130111033578"
  },
  {
    "id":72.0,
    "slug":"cofffey-park",
    "title":"Coffey Park",
    "address":null,
    "latitude":40.6776782,
    "longitude":-74.0084371,
    "latLng":"40.6776782,-74.0084371"
  },
  {
    "id":73.0,
    "slug":"portside-newyork",
    "title":"PortSide NewYork",
    "address":"Pier 11, Atlantic Basin",
    "latitude":40.6804466,
    "longitude":-74.0129419,
    "latLng":"40.6804466,-74.0129419"
  },
  {
    "id":74.0,
    "slug":"arianna-villafane",
    "title":"Arianna Villafane",
    "address":"Coffey Park",
    "latitude":40.6777092,
    "longitude":-74.0073881,
    "latLng":"40.6777092,-74.0073881"
  },
  {
    "id":75.0,
    "slug":"valentina-tepepa",
    "title":"Valentina Tepepa",
    "address":"Red Hook Initiative",
    "latitude":40.6768375,
    "longitude":-74.0045369,
    "latLng":"40.6768375,-74.0045369"
  },
  {
    "id":76.0,
    "slug":"tiffany-agront",
    "title":"Tiffany Agront",
    "address":"Coffey Park",
    "latitude":40.6777602,
    "longitude":-74.0079994,
    "latLng":"40.6777602,-74.0079994"
  },
  {
    "id":77.0,
    "slug":"nevaeh-middleton",
    "title":"Nevaeh Middleton",
    "address":"Coffey Park",
    "latitude":40.6777602,
    "longitude":-74.0079994,
    "latLng":"40.6777602,-74.0079994"
  },
  {
    "id":80.0,
    "slug":"deandre-white",
    "title":"Deandre White",
    "address":"Bush Clinton Playground",
    "latitude":40.6735273,
    "longitude":-74.0028126,
    "latLng":"40.6735273,-74.0028126"
  },
  {
    "id":1.0,
    "slug":"50-kent-ave",
    "title":"50 Kent Avenue \/ Williamsburg Works ",
    "address":"50 Kent Avenue",
    "latitude":40.72335117,
    "longitude":-73.95959838,
    "latLng":"40.72335117,-73.95959838"
  },
  {
    "id":2.0,
    "slug":"87-kent-ave",
    "title":"56 North 9th Street \/ 87 Kent Avenue",
    "address":"87 Kent Avenue",
    "latitude":40.72141074,
    "longitude":-73.96065274,
    "latLng":"40.72141074,-73.96065274"
  },
  {
    "id":3.0,
    "slug":"149-kent-ave",
    "title":"149 Kent Avenue",
    "address":"149 Kent Avenue",
    "latitude":40.71931265,
    "longitude":-73.96256503,
    "latLng":"40.71931265,-73.96256503"
  },
  {
    "id":4.0,
    "slug":"230-kent-ave",
    "title":"230 Kent Avenue \/ Fyn Paint & Lacquer Co., Inc",
    "address":"230 Kent Avenue",
    "latitude":40.71751304,
    "longitude":-73.9654088,
    "latLng":"40.71751304,-73.9654088"
  },
  {
    "id":5.0,
    "slug":"416-kent-ave",
    "title":"416 Kent Avenue \/ 420 Kent Avenue",
    "address":"416 Kent Avenue",
    "latitude":40.71055227,
    "longitude":-73.96897063,
    "latLng":"40.71055227,-73.96897063"
  },
  {
    "id":6.0,
    "slug":"470-kent-ave",
    "title":"470 Kent Avenue",
    "address":"470 Kent Avenue",
    "latitude":40.70765454,
    "longitude":-73.968713,
    "latLng":"40.70765454,-73.968713"
  },
  {
    "id":7.0,
    "slug":"mccarren-pool",
    "title":"McCarren Pool",
    "address":"776 Lorimer St",
    "latitude":40.72036532,
    "longitude":-73.94929039,
    "latLng":"40.72036532,-73.94929039"
  },
  {
    "id":8.0,
    "slug":"gretsch-building",
    "title":"Gretsch Building",
    "address":"60 Broadway",
    "latitude":40.71045667,
    "longitude":-73.9660422,
    "latLng":"40.71045667,-73.9660422"
  },
  {
    "id":9.0,
    "slug":"MPJ-park",
    "title":"Marsha P. Johnson Park",
    "address":"90 Kent Avenue",
    "latitude":40.72162338,
    "longitude":-73.96205058,
    "latLng":"40.72162338,-73.96205058"
  },
  {
    "id":11.0,
    "slug":"williamsburg-bridge",
    "title":"Williamsburg Bridge",
    "address":null,
    "latitude":40.71374485,
    "longitude":-73.97240339,
    "latLng":"40.71374485,-73.97240339"
  },
  {
    "id":12.0,
    "slug":"mccarren-park",
    "title":"McCarren Park",
    "address":"776 Lorimer St",
    "latitude":40.72124188,
    "longitude":-73.95242927,
    "latLng":"40.72124188,-73.95242927"
  },
  {
    "id":14.0,
    "slug":"230-kent-ave",
    "title":"230 Kent Avenue",
    "address":"230 Kent Avenue",
    "latitude":40.71747236,
    "longitude":-73.96540879,
    "latLng":"40.71747236,-73.96540879"
  },
  {
    "id":15.0,
    "slug":"satmar-hasidic-community",
    "title":"Satmar: Hasidic Community",
    "address":"274 Keap St",
    "latitude":40.70671469,
    "longitude":-73.95567386,
    "latLng":"40.70671469,-73.95567386"
  },
  {
    "id":16.0,
    "slug":"fillmore-place",
    "title":"Fillmore Place",
    "address":"662 Driggs Avenue",
    "latitude":40.71478215,
    "longitude":-73.95928742,
    "latLng":"40.71478215,-73.95928742"
  },
  {
    "id":17.0,
    "slug":"king-county-saving-bank",
    "title":"Kings County Savings Bank",
    "address":"135 Broadway",
    "latitude":40.71066855,
    "longitude":-73.96358546,
    "latLng":"40.71066855,-73.96358546"
  },
  {
    "id":18.0,
    "slug":"our-lady-of-mount-carmel",
    "title":"Our Lady of Mount Carmel \/ Giglio Feast",
    "address":" 275 N 8th St",
    "latitude":40.7158257,
    "longitude":-73.9526078,
    "latLng":"40.7158257,-73.9526078"
  },
  {
    "id":19.0,
    "slug":"154-north-7-ave",
    "title":"154 North 7th Street",
    "address":"154 North 7th Street ",
    "latitude":40.71797912,
    "longitude":-73.95832524,
    "latLng":"40.71797912,-73.95832524"
  },
  {
    "id":20.0,
    "slug":"34-berry-street",
    "title":"34 Berry Street",
    "address":"34 Berry Street",
    "latitude":40.72132589,
    "longitude":-73.95647736,
    "latLng":"40.72132589,-73.95647736"
  },
  {
    "id":21.0,
    "slug":"radiac-research-corp",
    "title":"\"NYC's Dirty Bomb\" \/ Radiac Research Corp.",
    "address":"261 Kent Avenue",
    "latitude":40.71592209,
    "longitude":-73.96605583,
    "latLng":"40.71592209,-73.96605583"
  },
  {
    "id":22.0,
    "slug":"500-kent-ave",
    "title":"500 Kent Avenue",
    "address":"500 Kent Avenue",
    "latitude":40.70679217,
    "longitude":-73.9687623,
    "latLng":"40.70679217,-73.9687623"
  },
  {
    "id":23.0,
    "slug":"100-south-4-street",
    "title":"100 South 4th Street",
    "address":"100 South 4th Street",
    "latitude":40.7123742,
    "longitude":-73.96355698,
    "latLng":"40.7123742,-73.96355698"
  },
  {
    "id":24.0,
    "slug":"williamsburg-bridgeview-apt",
    "title":"105 South 5th Street \/ Williamsburg Bridgeview Apartments",
    "address":"105 South 5th Street",
    "latitude":40.71174826,
    "longitude":-73.96410887,
    "latLng":"40.71174826,-73.96410887"
  },
  {
    "id":25.0,
    "slug":"chromium-plating-polishing",
    "title":"373 Wythe Avenue \/ Chromium Plating & Polishing",
    "address":"373 Wythe Avenue",
    "latitude":40.71246556,
    "longitude":-73.96570759,
    "latLng":"40.71246556,-73.96570759"
  },
  {
    "id":28.0,
    "slug":"all-plating-corp",
    "title":"154 North 7th Street \/ All Plating Corporation",
    "address":"154 North 7th Street",
    "latitude":40.7181672,
    "longitude":-73.95825953,
    "latLng":"40.7181672,-73.95825953"
  },
  {
    "id":29.0,
    "slug":"slattery-gas-stove",
    "title":"171 Wallabout Street \/ Slattery Gas Stove",
    "address":"171 Wallabout Street",
    "latitude":40.70051376,
    "longitude":-73.95308497,
    "latLng":"40.70051376,-73.95308497"
  },
  {
    "id":30.0,
    "slug":"nb-pipeline",
    "title":"North Brooklyn Pipeline",
    "address":"287 Maspeth Ave, Brooklyn, NY 11211",
    "latitude":40.71987679,
    "longitude":-73.92650085,
    "latLng":"40.71987679,-73.92650085"
  },
  {
    "id":32.0,
    "slug":"public-school-71k",
    "title":"Public School 71K",
    "address":"125 Heyward Street",
    "latitude":40.70190879,
    "longitude":-73.95629139,
    "latLng":"40.70190879,-73.95629139"
  },
  {
    "id":33.0,
    "slug":"williamsburgh-saving-bank ",
    "title":"Williamsburgh Savings Bank ",
    "address":"175 Broadway ",
    "latitude":40.71039615,
    "longitude":-73.9625705,
    "latLng":"40.71039615,-73.9625705"
  },
  {
    "id":34.0,
    "slug":"peter-luger",
    "title":"Peter Luger",
    "address":"178 Broadway",
    "latitude":40.70995462,
    "longitude":-73.96249447,
    "latLng":"40.70995462,-73.96249447"
  },
  {
    "id":35.0,
    "slug":"gottliebs-restaurant",
    "title":"Gottlieb's Restaurant",
    "address":"352 Roebling Street",
    "latitude":40.70775354,
    "longitude":-73.96132645,
    "latLng":"40.70775354,-73.96132645"
  },
  {
    "id":37.0,
    "slug":"mccarren-park",
    "title":"McCarren Park",
    "address":"776 Lorimer Street",
    "latitude":40.7203646,
    "longitude":-73.94970239,
    "latLng":"40.7203646,-73.94970239"
  },
  {
    "id":38.0,
    "slug":"continental-army",
    "title":"Continental Army Plaza",
    "address":"South 4th Street (across from 195)",
    "latitude":40.71074887,
    "longitude":-73.96050266,
    "latLng":"40.71074887,-73.96050266"
  },
  {
    "id":39.0,
    "slug":"domino-sugar",
    "title":"Domino Sugar Refinery",
    "address":"316 Kent Avenue",
    "latitude":40.71403462,
    "longitude":-73.96723079,
    "latLng":"40.71403462,-73.96723079"
  },
  {
    "id":40.0,
    "slug":"domino-sugar",
    "title":"Domino Sugar Refinery",
    "address":"316 Kent Avenue",
    "latitude":40.71405089,
    "longitude":-73.96726297,
    "latLng":"40.71405089,-73.96726297"
  },
  {
    "id":41.0,
    "slug":"los-sures-southside",
    "title":"Los Sures",
    "address":"120 S 1st St, Brooklyn, NY 11249",
    "latitude":40.71430535,
    "longitude":-73.96255708,
    "latLng":"40.71430535,-73.96255708"
  },
  {
    "id":42.0,
    "slug":"b-qe",
    "title":"Brooklyn-Queens Expressway",
    "address":"252 S 4th St, Brooklyn, NY 11211",
    "latitude":40.71049783,
    "longitude":-73.95964422,
    "latLng":"40.71049783,-73.95964422"
  },
  {
    "id":44.0,
    "slug":"el-puente",
    "title":"El Puente",
    "address":"211 South 4th Street",
    "latitude":40.71111856,
    "longitude":-73.95956502,
    "latLng":"40.71111856,-73.95956502"
  },
  {
    "id":45.0,
    "slug":"times-up",
    "title":"Time's Up!",
    "address":"99 South 6th Street",
    "latitude":40.7110829,
    "longitude":-73.96456047,
    "latLng":"40.7110829,-73.96456047"
  },
  {
    "id":46.0,
    "slug":"southside-garden",
    "title":"Southside Community Garden",
    "address":"South 4th and Hewes Street",
    "latitude":40.70801874,
    "longitude":-73.95178978,
    "latLng":"40.70801874,-73.95178978"
  },
  {
    "id":47.0,
    "slug":"union-docs",
    "title":"UnionDocs",
    "address":"322 Union Avenue",
    "latitude":40.71016147,
    "longitude":-73.95073606,
    "latLng":"40.71016147,-73.95073606"
  },
  {
    "id":49.0,
    "slug":"reformed-church",
    "title":"Greenpoint Reformed Church Food Pantry",
    "address":"136 Milton Street",
    "latitude":40.729436,
    "longitude":-73.95498743,
    "latLng":"40.729436,-73.95498743"
  },
  {
    "id":50.0,
    "slug":"bk-rot",
    "title":"BK ROT",
    "address":"1278 Myrtle Avenue",
    "latitude":40.69801496,
    "longitude":-73.92594528,
    "latLng":"40.69801496,-73.92594528"
  },
  {
    "id":51.0,
    "slug":"mccarren-greenmarket",
    "title":"McCarren Park Greenmarket",
    "address":"North 12th St & Union Avenue",
    "latitude":40.71950399,
    "longitude":-73.95248573,
    "latLng":"40.71950399,-73.95248573"
  },
  {
    "id":52.0,
    "slug":"sure-we-can",
    "title":"Sure We Can",
    "address":"219 McKibbin Street",
    "latitude":40.70567864,
    "longitude":-73.93893049,
    "latLng":"40.70567864,-73.93893049"
  },
  {
    "id":53.0,
    "slug":"big-reuse",
    "title":"Big Reuse",
    "address":"1 12th Street",
    "latitude":40.67219119,
    "longitude":-73.99658103,
    "latLng":"40.67219119,-73.99658103"
  },
  {
    "id":55.0,
    "slug":"keap-fourth",
    "title":"Keap Fourth",
    "address":"347 Keap Street",
    "latitude":40.7092329,
    "longitude":-73.95483384,
    "latLng":"40.7092329,-73.95483384"
  },
  {
    "id":56.0,
    "slug":"oko-farms",
    "title":"Oko Farms",
    "address":"105 River Street",
    "latitude":40.71831068,
    "longitude":-73.96561502,
    "latLng":"40.71831068,-73.96561502"
  },
  {
    "id":57.0,
    "slug":"berry-garden",
    "title":"Berry Street Community Garden",
    "address":"301 Berry Street",
    "latitude":40.71354529,
    "longitude":-73.96354592,
    "latLng":"40.71354529,-73.96354592"
  },
  {
    "id":58.0,
    "slug":"mccareen-garden",
    "title":"McCarren Park Demonstration Garden ",
    "address":"457 Leonard Street",
    "latitude":40.72078723,
    "longitude":-73.94881882,
    "latLng":"40.72078723,-73.94881882"
  },
  {
    "id":59.0,
    "slug":"eagle-farm",
    "title":"Eagle Street Rooftop Farm",
    "address":"44 Eagle Street",
    "latitude":40.73467711,
    "longitude":-73.95933832,
    "latLng":"40.73467711,-73.95933832"
  },
  {
    "id":60.0,
    "slug":"square-roots",
    "title":"Square Roots Urban Growers",
    "address":"630 Flushing Avenue",
    "latitude":40.69980819,
    "longitude":-73.94794706,
    "latLng":"40.69980819,-73.94794706"
  },
  {
    "id":61.0,
    "slug":"nb-neighbors",
    "title":"North Brooklyn Neighbors",
    "address":"240 Kent Avenue",
    "latitude":40.71716922,
    "longitude":-73.96568217,
    "latLng":"40.71716922,-73.96568217"
  },
  {
    "id":62.0,
    "slug":"newtown-creek",
    "title":"Newtown Creek Alliance",
    "address":"327 Greenpoint Ave, Brooklyn, NY 11222",
    "latitude":40.73599071,
    "longitude":-73.94860787,
    "latLng":"40.73599071,-73.94860787"
  },
  {
    "id":63.0,
    "slug":"nb-parks",
    "title":"North Brooklyn Parks Alliance",
    "address":"86 Kent Avenue",
    "latitude":40.72309673,
    "longitude":-73.96219673,
    "latLng":"40.72309673,-73.96219673"
  },
  {
    "id":64.0,
    "slug":"kingsland-wildflowers",
    "title":"Kingsland Wildflowers Green Roof & Community Engagement Center",
    "address":"520 Kingsland Avenue",
    "latitude":40.73452791,
    "longitude":-73.94345452,
    "latLng":"40.73452791,-73.94345452"
  },
  {
    "id":65.0,
    "slug":"java-garden",
    "title":"Java St Community Garden",
    "address":"59 Java Street",
    "latitude":40.73154896,
    "longitude":-73.95880854,
    "latLng":"40.73154896,-73.95880854"
  },
  {
    "id":66.0,
    "slug":"human-impact",
    "title":"Human Impacts Institute",
    "address":"312 South 3rd Street",
    "latitude":40.70970387,
    "longitude":-73.95490114,
    "latLng":"40.70970387,-73.95490114"
  },
  {
    "id":67.0,
    "slug":"huairou-commission",
    "title":"Huairou Commission",
    "address":"249 Manhattan Avenue",
    "latitude":40.71230099,
    "longitude":-73.94594999,
    "latLng":"40.71230099,-73.94594999"
  },
  {
    "id":68.0,
    "slug":"nuleadership-center",
    "title":"The Center for NuLeadership on Human Justice and Healing",
    "address":"7 Marcus Garvey Boulevard",
    "latitude":40.70004989,
    "longitude":-73.9412053,
    "latLng":"40.70004989,-73.9412053"
  },
  {
    "id":69.0,
    "slug":"service-center-wbrg",
    "title":"Community Services Center for Greater Williamsburg",
    "address":"378 Flushing Ave ",
    "latitude":40.69843133,
    "longitude":-73.95942609,
    "latLng":"40.69843133,-73.95942609"
  },
  {
    "id":71.0,
    "slug":"city-bike-71",
    "title":"Citi Bike Dock",
    "address":"Franklin St and Dupont St ",
    "latitude":40.73569268,
    "longitude":-73.95853173,
    "latLng":"40.73569268,-73.95853173"
  },
  {
    "id":72.0,
    "slug":"city-bike-72",
    "title":"Citi Bike Dock",
    "address":"McGuinness Blvd and Eagle St",
    "latitude":40.73556782,
    "longitude":-73.95279583,
    "latLng":"40.73556782,-73.95279583"
  },
  {
    "id":73.0,
    "slug":"city-bike-73",
    "title":"Citi Bike Dock",
    "address":"Green St and McGuinness Blvd ",
    "latitude":40.73416586,
    "longitude":-73.95223704,
    "latLng":"40.73416586,-73.95223704"
  },
  {
    "id":74.0,
    "slug":"city-bike-74",
    "title":"Citi Bike Dock",
    "address":"India St pier",
    "latitude":40.73196242,
    "longitude":-73.96128192,
    "latLng":"40.73196242,-73.96128192"
  },
  {
    "id":75.0,
    "slug":"city-bike-75",
    "title":"Citi Bike Dock",
    "address":"Huron St and Frankiln St ",
    "latitude":40.73283884,
    "longitude":-73.95826,
    "latLng":"40.73283884,-73.95826"
  },
  {
    "id":76.0,
    "slug":"city-bike-76",
    "title":"Citi Bike Dock",
    "address":"India St and Manhattan Ave ",
    "latitude":40.73250074,
    "longitude":-73.95506434,
    "latLng":"40.73250074,-73.95506434"
  },
  {
    "id":77.0,
    "slug":"city-bike-77",
    "title":"Citi Bike Dock",
    "address":"Kent St and McGuinness Blvd",
    "latitude":40.7315164,
    "longitude":-73.95161,
    "latLng":"40.7315164,-73.95161"
  },
  {
    "id":78.0,
    "slug":"city-bike-78",
    "title":"Citi Bike Dock",
    "address":"Calyer St and Jewel St ",
    "latitude":40.73004324,
    "longitude":-73.94842219,
    "latLng":"40.73004324,-73.94842219"
  },
  {
    "id":79.0,
    "slug":"city-bike-79",
    "title":"Citi Bike Dock",
    "address":"Greenpoint Ave and Manhattan Ave",
    "latitude":40.73088029,
    "longitude":-73.95418361,
    "latLng":"40.73088029,-73.95418361"
  },
  {
    "id":80.0,
    "slug":"city-bike-80",
    "title":"Citi Bike Dock",
    "address":"Milton St and Franklin St ",
    "latitude":40.72923072,
    "longitude":-73.95782219,
    "latLng":"40.72923072,-73.95782219"
  },
  {
    "id":81.0,
    "slug":"city-bike-81",
    "title":"Citi Bike Dock",
    "address":"Lorimer St and Calyer St ",
    "latitude":40.72806381,
    "longitude":-73.95415073,
    "latLng":"40.72806381,-73.95415073"
  },
  {
    "id":82.0,
    "slug":"city-bike-82",
    "title":"Citi Bike Dock",
    "address":"Meserole Ave and Manhattan Ave ",
    "latitude":40.72728091,
    "longitude":-73.95301266,
    "latLng":"40.72728091,-73.95301266"
  },
  {
    "id":83.0,
    "slug":"city-bike-83",
    "title":"Citi Bike Dock",
    "address":"Banker St and Meserole Ave ",
    "latitude":40.72623885,
    "longitude":-73.95624219,
    "latLng":"40.72623885,-73.95624219"
  },
  {
    "id":84.0,
    "slug":"city-bike-84",
    "title":"Citi Bike Dock",
    "address":"N 15 St and Wythe Ave ",
    "latitude":40.72428265,
    "longitude":-73.955736,
    "latLng":"40.72428265,-73.955736"
  },
  {
    "id":85.0,
    "slug":"city-bike-85",
    "title":"Citi Bike Dock",
    "address":"N 11 St and Kent Ave  ",
    "latitude":40.72267703,
    "longitude":-73.95924076,
    "latLng":"40.72267703,-73.95924076"
  },
  {
    "id":86.0,
    "slug":"city-bike-86",
    "title":"Citi Bike Dock",
    "address":"Bedford Ave and Nassau Ave ",
    "latitude":40.72331162,
    "longitude":-73.95214466,
    "latLng":"40.72331162,-73.95214466"
  },
  {
    "id":87.0,
    "slug":"city-bike-87",
    "title":"Citi Bike Dock",
    "address":"Leonard St and Nassau Ave ",
    "latitude":40.7241193,
    "longitude":-73.94988652,
    "latLng":"40.7241193,-73.94988652"
  },
  {
    "id":88.0,
    "slug":"city-bike-88",
    "title":"Citi Bike Dock",
    "address":"Nassau Ave and Newell St ",
    "latitude":40.72507277,
    "longitude":-73.94756912,
    "latLng":"40.72507277,-73.94756912"
  },
  {
    "id":89.0,
    "slug":"city-bike-89",
    "title":"Citi Bike Dock",
    "address":"Nassau Ave Russell St ",
    "latitude":40.72572446,
    "longitude":-73.94436146,
    "latLng":"40.72572446,-73.94436146"
  },
  {
    "id":90.0,
    "slug":"city-bike-90",
    "title":"Citi Bike Dock",
    "address":"Kingsland Ave and Nassau Ave ",
    "latitude":40.72594886,
    "longitude":-73.94177292,
    "latLng":"40.72594886,-73.94177292"
  },
  {
    "id":91.0,
    "slug":"city-bike-91",
    "title":"Citi Bike Dock",
    "address":"Driggs Ave and N Henry St ",
    "latitude":40.72342886,
    "longitude":-73.94305854,
    "latLng":"40.72342886,-73.94305854"
  },
  {
    "id":92.0,
    "slug":"city-bike-92",
    "title":"Citi Bike Dock",
    "address":"Morgan Ave and Lombardy St ",
    "latitude":40.72245513,
    "longitude":-73.93890073,
    "latLng":"40.72245513,-73.93890073"
  },
  {
    "id":93.0,
    "slug":"city-bike-93",
    "title":"Citi Bike Dock",
    "address":"Engert Ave and McGuinness Blvd ",
    "latitude":40.72175892,
    "longitude":-73.94542733,
    "latLng":"40.72175892,-73.94542733"
  },
  {
    "id":94.0,
    "slug":"city-bike-94",
    "title":"Citi Bike Dock",
    "address":"Manhattan Ave and Leonard Ave ",
    "latitude":40.72157906,
    "longitude":-73.94854957,
    "latLng":"40.72157906,-73.94854957"
  },
  {
    "id":95.0,
    "slug":"city-bike-95",
    "title":"Citi Bike Dock",
    "address":"Driggs Ave and Lorimer St ",
    "latitude":40.72198643,
    "longitude":-73.95038322,
    "latLng":"40.72198643,-73.95038322"
  },
  {
    "id":96.0,
    "slug":"city-bike-96",
    "title":"Citi Bike Dock",
    "address":"Kent Ave and N 7 St ",
    "latitude":40.72057104,
    "longitude":-73.96164736,
    "latLng":"40.72057104,-73.96164736"
  },
  {
    "id":97.0,
    "slug":"city-bike-97",
    "title":"Citi Bike Dock",
    "address":"Berry St and N 8 St ",
    "latitude":40.71919651,
    "longitude":-73.95861093,
    "latLng":"40.71919651,-73.95861093"
  },
  {
    "id":98.0,
    "slug":"city-bike-98",
    "title":"Citi Bike Dock",
    "address":"N 12 St and Bedford Ave ",
    "latitude":40.72096894,
    "longitude":-73.95483637,
    "latLng":"40.72096894,-73.95483637"
  },
  {
    "id":99.0,
    "slug":"city-bike-99",
    "title":"Citi Bike Dock",
    "address":"Frost St and Deveboise Ave ",
    "latitude":40.71900701,
    "longitude":-73.93952292,
    "latLng":"40.71900701,-73.93952292"
  },
  {
    "id":100.0,
    "slug":"city-bike-100",
    "title":"Citi Bike Dock",
    "address":"Richardson St and N Henry St ",
    "latitude":40.71922525,
    "longitude":-73.9422369,
    "latLng":"40.71922525,-73.9422369"
  },
  {
    "id":101.0,
    "slug":"city-bike-101",
    "title":"Citi Bike Dock",
    "address":"Graham Ave and Herbert St ",
    "latitude":40.71952066,
    "longitude":-73.94495016,
    "latLng":"40.71952066,-73.94495016"
  },
  {
    "id":102.0,
    "slug":"city-bike-102",
    "title":"Citi Bike Dock",
    "address":"Bayard St and Leonard St ",
    "latitude":40.7193427,
    "longitude":-73.94887536,
    "latLng":"40.7193427,-73.94887536"
  },
  {
    "id":103.0,
    "slug":"city-bike-103",
    "title":"Citi Bike Dock",
    "address":"Union Ave and 12 St ",
    "latitude":40.71979335,
    "longitude":-73.95234199,
    "latLng":"40.71979335,-73.95234199"
  },
  {
    "id":104.0,
    "slug":"city-bike-104",
    "title":"Citi Bike Dock",
    "address":"N 6 St and Bedford Ave ",
    "latitude":40.71762245,
    "longitude":-73.95859523,
    "latLng":"40.71762245,-73.95859523"
  },
  {
    "id":105.0,
    "slug":"city-bike-105",
    "title":"Citi Bike Dock",
    "address":"Driggs St and N 9 St ",
    "latitude":40.71859203,
    "longitude":-73.95515844,
    "latLng":"40.71859203,-73.95515844"
  },
  {
    "id":106.0,
    "slug":"city-bike-106",
    "title":"Citi Bike Dock",
    "address":"Whyte Ave and Metropolitan Ave ",
    "latitude":40.717242,
    "longitude":-73.96322653,
    "latLng":"40.717242,-73.96322653"
  },
  {
    "id":107.0,
    "slug":"city-bike-107",
    "title":"Citi Bike Dock",
    "address":"Metropolitan Ave and Bedford Ave ",
    "latitude":40.71549466,
    "longitude":-73.9601661,
    "latLng":"40.71549466,-73.9601661"
  },
  {
    "id":108.0,
    "slug":"city-bike-108",
    "title":"Citi Bike Dock",
    "address":"Roebling St and N 4 St ",
    "latitude":40.71485262,
    "longitude":-73.95747583,
    "latLng":"40.71485262,-73.95747583"
  },
  {
    "id":109.0,
    "slug":"city-bike-109",
    "title":"Citi Bike Dock",
    "address":"Metropolitan Ave and Meeker Ave ",
    "latitude":40.71428759,
    "longitude":-73.95237599,
    "latLng":"40.71428759,-73.95237599"
  },
  {
    "id":110.0,
    "slug":"city-bike-110",
    "title":"Citi Bike Dock",
    "address":"Devoe St and Lorimer St ",
    "latitude":40.71355549,
    "longitude":-73.9490282,
    "latLng":"40.71355549,-73.9490282"
  },
  {
    "id":111.0,
    "slug":"city-bike-111",
    "title":"Citi Bike Dock",
    "address":"Graham Ave and Conselyea St ",
    "latitude":40.71533815,
    "longitude":-73.944507,
    "latLng":"40.71533815,-73.944507"
  },
  {
    "id":112.0,
    "slug":"city-bike-112",
    "title":"Citi Bike Dock",
    "address":"Sharon St and Olive St ",
    "latitude":40.71556441,
    "longitude":-73.93856,
    "latLng":"40.71556441,-73.93856"
  },
  {
    "id":113.0,
    "slug":"city-bike-113",
    "title":"Citi Bike Dock",
    "address":"Morgan Ave and Maspeth Ave ",
    "latitude":40.71687654,
    "longitude":-73.93637,
    "latLng":"40.71687654,-73.93637"
  },
  {
    "id":114.0,
    "slug":"city-bike-114",
    "title":"Citi Bike Dock",
    "address":"Metropolitan Ave and Vandervoort Ave ",
    "latitude":40.71441196,
    "longitude":-73.93321927,
    "latLng":"40.71441196,-73.93321927"
  },
  {
    "id":115.0,
    "slug":"city-bike-115",
    "title":"Citi Bike Dock",
    "address":"Devoe St and Morgan Ave ",
    "latitude":40.71402242,
    "longitude":-73.93516073,
    "latLng":"40.71402242,-73.93516073"
  },
  {
    "id":116.0,
    "slug":"city-bike-116",
    "title":"Citi Bike Dock",
    "address":"Powers St and Olive St ",
    "latitude":40.71346582,
    "longitude":-73.93891854,
    "latLng":"40.71346582,-73.93891854"
  },
  {
    "id":117.0,
    "slug":"city-bike-117",
    "title":"Citi Bike Dock",
    "address":"Bushwick Ave and Powers St ",
    "latitude":40.71259043,
    "longitude":-73.94096782,
    "latLng":"40.71259043,-73.94096782"
  },
  {
    "id":118.0,
    "slug":"city-bike-118",
    "title":"Citi Bike Dock",
    "address":"Graham Ave and Grand St ",
    "latitude":40.71203376,
    "longitude":-73.94406692,
    "latLng":"40.71203376,-73.94406692"
  },
  {
    "id":119.0,
    "slug":"city-bike-119",
    "title":"Citi Bike Dock",
    "address":"Hope St and Union Ave ",
    "latitude":40.71186866,
    "longitude":-73.95140237,
    "latLng":"40.71186866,-73.95140237"
  },
  {
    "id":120.0,
    "slug":"city-bike-120",
    "title":"Citi Bike Dock",
    "address":"Grand St and Havemeyer St ",
    "latitude":40.71301476,
    "longitude":-73.95702402,
    "latLng":"40.71301476,-73.95702402"
  },
  {
    "id":121.0,
    "slug":"city-bike-121",
    "title":"Citi Bike Dock",
    "address":"S 3 St and Bedford Ave ",
    "latitude":40.71282445,
    "longitude":-73.96265473,
    "latLng":"40.71282445,-73.96265473"
  },
  {
    "id":122.0,
    "slug":"city-bike-122",
    "title":"Citi Bike Dock",
    "address":"S 4 St and and Whyte Ave ",
    "latitude":40.71299523,
    "longitude":-73.9659163,
    "latLng":"40.71299523,-73.9659163"
  },
  {
    "id":123.0,
    "slug":"city-bike-123",
    "title":"Citi Bike Dock",
    "address":"Broadway and Berry St ",
    "latitude":40.71064066,
    "longitude":-73.96523987,
    "latLng":"40.71064066,-73.96523987"
  },
  {
    "id":124.0,
    "slug":"city-bike-124",
    "title":"Citi Bike Dock",
    "address":"Kent Ave and S 11 St ",
    "latitude":40.70775874,
    "longitude":-73.96845822,
    "latLng":"40.70775874,-73.96845822"
  },
  {
    "id":125.0,
    "slug":"city-bike-125",
    "title":"Citi Bike Dock",
    "address":"Division Ave and Marcy Ave ",
    "latitude":40.70722524,
    "longitude":-73.95799999,
    "latLng":"40.70722524,-73.95799999"
  },
  {
    "id":126.0,
    "slug":"city-bike-126",
    "title":"Citi Bike Dock",
    "address":"Division Ave and Hooper St ",
    "latitude":40.70700464,
    "longitude":-73.9545105,
    "latLng":"40.70700464,-73.9545105"
  },
  {
    "id":127.0,
    "slug":"city-bike-127",
    "title":"Citi Bike Dock",
    "address":"Stagg St and Union Ave ",
    "latitude":40.70890904,
    "longitude":-73.95097406,
    "latLng":"40.70890904,-73.95097406"
  },
  {
    "id":128.0,
    "slug":"city-bike-128",
    "title":"Citi Bike Dock",
    "address":"Leonard St and Maujer St ",
    "latitude":40.71055553,
    "longitude":-73.94707023,
    "latLng":"40.71055553,-73.94707023"
  },
  {
    "id":129.0,
    "slug":"city-bike-129",
    "title":"Citi Bike Dock",
    "address":"Scholes St and Manhattan Ave ",
    "latitude":40.70887447,
    "longitude":-73.94476594,
    "latLng":"40.70887447,-73.94476594"
  },
  {
    "id":130.0,
    "slug":"city-bike-130",
    "title":"Citi Bike Dock",
    "address":"Montrose Ave and Bushwick Ave ",
    "latitude":40.70783231,
    "longitude":-73.94014024,
    "latLng":"40.70783231,-73.94014024"
  },
  {
    "id":131.0,
    "slug":"city-bike-131",
    "title":"Citi Bike Dock",
    "address":"White St and Johnson Ave ",
    "latitude":40.70738544,
    "longitude":-73.93582073,
    "latLng":"40.70738544,-73.93582073"
  },
  {
    "id":132.0,
    "slug":"moore-market",
    "title":"Moore Street Market",
    "address":"110 Moore St.",
    "latitude":40.70362488,
    "longitude":-73.94122515,
    "latLng":"40.70362488,-73.94122515"
  },
  {
    "id":133.0,
    "slug":"nu-hart",
    "title":"NuHart Plastic Manufacturing",
    "address":" 280 Franklin Street",
    "latitude":40.73609032,
    "longitude":-73.95830662,
    "latLng":"40.73609032,-73.95830662"
  },
  {
    "id":134.0,
    "slug":"bedford-subway",
    "title":"Bedford L Subway Station",
    "address":null,
    "latitude":40.7180092,
    "longitude":-73.95750637,
    "latLng":"40.7180092,-73.95750637"
  },
  {
    "id":135.0,
    "slug":"el-puente",
    "title":"El Puente",
    "address":"211 South 4th Street",
    "latitude":40.71106164,
    "longitude":-73.95956502,
    "latLng":"40.71106164,-73.95956502"
  },
  {
    "id":136.0,
    "slug":"149-south4",
    "title":"149 South 4th Street",
    "address":"149 South 4th Street",
    "latitude":40.71189489,
    "longitude":-73.9621261,
    "latLng":"40.71189489,-73.9621261"
  },
  {
    "id":137.0,
    "slug":"tonitas-club",
    "title":"To\u00f1ita's \/ Caribbean Social Club",
    "address":"244 Grand Street",
    "latitude":40.7134702,
    "longitude":-73.95908098,
    "latLng":"40.7134702,-73.95908098"
  },
  {
    "id":138.0,
    "slug":"wbrg-houses",
    "title":"Williamsburg Houses",
    "address":"87 Ten Eyck Street",
    "latitude":40.71022633,
    "longitude":-73.94667199,
    "latLng":"40.71022633,-73.94667199"
  },
  {
    "id":139.0,
    "slug":"newtown-creek",
    "title":"Newtown Creek",
    "address":"327 Greenpoint Ave, Brooklyn, NY 11222",
    "latitude":40.73599071,
    "longitude":-73.94860787,
    "latLng":"40.73599071,-73.94860787"
  },
  {
    "id":140.0,
    "slug":"99-commercial",
    "title":"99 Commercial Street",
    "address":"99 Commercial Street",
    "latitude":40.73802296,
    "longitude":-73.95589654,
    "latLng":"40.73802296,-73.95589654"
  },
  {
    "id":141.0,
    "slug":"wnyc-transmitter-park",
    "title":"WNYC Transmitter Park",
    "address":null,
    "latitude":40.7299148987,
    "longitude":-73.9607818707,
    "latLng":"40.7299148986701,-73.9607818706684"
  },
  {
    "id":143.0,
    "slug":"the-friend-of-bqgreen",
    "title":"The Friends of BQGreen",
    "address":"Rodney St., S. 4 St. and S. 5 St., Brooklyn, NY 11211",
    "latitude":40.70914829,
    "longitude":-73.95631979,
    "latLng":"40.70914829,-73.95631979"
  },
  {
    "id": 300,
    "slug": "arianna-villafane",
    "title": "Arianna Villafane",
    "address": "Coffey Park",
    "latitude": 40.6777092,
    "longitude": -74.0073881,
    "latLng": "40.6777092,-74.0073881"
  },
  {
    "id": 301,
    "title": "Valentina Tepepa",
    "slug": "valentina-tepepa",
    "address": "Red Hook Initiative",
    "latitude": 40.6768375,
    "longitude": -74.0045369
  },
  {
    "id": 302,
    "slug": "tiffany-agront",
    "title": "Tiffany Agront",
    "address": "Coffey Park",
    "latitude": 40.6777602,
    "longitude": -74.0079994
  },
  {
    "id": 303,
    "slug": "nevaeh-middleton",
    "title": "Nevaeh Middleton",
    "address": "Coffey Park",
    "latitude": 40.6777602,
    "longitude": -74.0079994
  },
  {
    "id": 304,
    "slug": "deandre-white",
    "title": "Deandre White",
    "address": "Bush Clinton Playground",
    "latitude": 40.6735273,
    "longitude": -74.0028126
  },
  {
    "id": 305,
    "slug": "maria-c-rosa",
    "title": "Maria C. Rosa",
    "address": "Los Sures Senior Center",
    "latitude": 40.714296,
    "longitude": -73.960841
  },
  {
    "id": 306,
    "slug": "titi-rodriguez",
    "title": "“Titi” Esmerenciana Rodríguez",
    "address": "Los Sures Senior Center",
    "latitude": 40.714630,
    "longitude": -73.961653
  },
  {
    "id": 307,
    "slug": "efrain-gonzalez",
    "title": "Efrain Gonzalez",
    "address": "Los Sures Senior Center",
    "latitude": 40.713647,
    "longitude": -73.961805
  },
  {
    "id": 308,
    "slug": "magali",
    "title": "Magali",
    "address": "Los Sures Senior Center",
    "latitude": 40.714675,
    "longitude": -73.963690
  },
  {
    "id": 309,
    "slug": "marcelo-and-luz",
    "title": "Marcelo & Luz Ortiz",
    "address": "Los Sures Senior Center",
    "latitude": 40.715150,
    "longitude": -73.962733
  }
]
