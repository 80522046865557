import { partnerLogos } from 'assets/logos'
import { data } from 'db'

export const allPartners = () => {
  return data.partners.map(partner => ({
    slug: partner.slug,
    href: partner.url,
    image: partnerLogos[partner.slug]
  }))
}

export const drpp = () => {
  const drpp = data.partners.find(p => p.slug === 'drpp')
  return {
    slug: drpp.slug,
    href: drpp.url,
    image: partnerLogos[drpp.slug]
  }
}
