import {useState,useEffect} from 'react'
import {data} from 'db'

function generateLayer(layer:any, google:any){
  switch(layer.type){
    case 'tiles':
      return new google.maps.ImageMapType({
        getTileUrl: (coord, zoom)=>{
          let url = layer.tileUrl
                   .replace("{z}", zoom )
                   .replace("{y}", coord.y)
                   .replace("{x}", coord.x);

          return url
        },
        tileSize : new google.maps.Size(256,256),
        maxZoom: 15,
        minZoom: 0,
        name: layer.slug
      })
    case 'image':
      let bounds = {
        north: layer.topLeft[0],
        south: layer.bottomRight[0],
        east: layer.bottomRight[1],
        west: layer.topLeft[1]
      }
      let overlay = new google.maps.GroundOverlay(
        layer.imageUrl,
        bounds
      )
      overlay.name = layer.slug 
      overlay.setOpacity(0.5)
      return overlay;
    default:
      console.log("Layer type not supported")
  }
}

export const useMapOverlays=(activeLayer: string, mapRef: any, google: any)=>{
  const [avaliableLayers, setAvalibleLayers] = useState<any[]>([])

  useEffect(()=>{
    if(google){
      const layers = data.overlayLayers.map(layer =>generateLayer(layer,google)) 
      setAvalibleLayers(layers)
    }
  },[google])

  useEffect(()=>{
    if(mapRef && google){
      mapRef.map_.overlayMapTypes.clear();
      avaliableLayers.filter(layer => layer.bounds).forEach(layer => layer.setMap(null))
      avaliableLayers.filter(layer => activeLayer ===layer.name)
                     .forEach(layer=>{
                       if(layer.bounds){
                         layer.setMap(mapRef.map_)
                       }
                       else{
                        mapRef.map_.overlayMapTypes.push(layer)
                       }
                     })
    }
  },[avaliableLayers,activeLayer,mapRef, google])
}
