import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { font } from 'styles/font'
import Markdown from 'react-native-markdown-display'

const mdStyles = StyleSheet.create({
  body: {
    fontFamily: font[400]
  },
  heading2: {
    fontFamily: font[800],
    textTransform: 'uppercase',
    marginBottom: 4
  },
  heading4: {
    fontFamily: font[300],
    textTransform: 'uppercase',
    marginBottom: 10,
    letterSpacing: 1
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 25
  }
})

export const StyledText: React.FC<{
  el?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'p'
    | 'title'
    | 'title-mini'
    | 'subtitle'
    | 'btn'
    | 'btn-alt'
    | 'map-key'
    | 'a'
    | 'sm'
  c?: string
  style?: any
}> = ({ el, c, style, ...props }) => {
  let face = font[400]
  let size = 14
  let transform = 'uppercase'
  let decoration = 'none'
  let color = c || '#3B413C'
  let line = 20
  let letter = 0
  let mb = 0
  switch (el) {
    case 'title':
      face = font[800]
      size = 64
      line = 64
      mb = 16
      break
    case 'title-mini':
      face = font[800]
      size = 24
      line = 23
      letter = 1
      break
    case 'subtitle':
      face = font[800]
      size = 12
      line = 14
      letter = 1.4
      break
    case 'h1':
      face = font[200]
      size = 28
      line = 32
      mb = 8
      letter = 1
      break
    case 'h2':
      face = font[800]
      size = 24
      line = 32
      letter = 1
      mb = 6
      break
    case 'h3':
      face = font[800]
      size = 18
      line = 24
      letter = 1.24
      break
    case 'h4':
      face = font[300]
      size = 14
      line = 24
      letter = 2
      break
    case 'h5':
      face = font[200]
      size = 14
      transform = 'none'
      letter = 0.5
      break
    case 'map-key':
      face = font[800]
      size = 14
      letter = 1
      break
    case 'btn':
      face = font[600]
      color = '#fff'
      letter = 1.5
      break
    case 'btn-alt':
      face = font[800]
      decoration = 'underline'
      size = 12
      break
    case 'a':
      transform = 'none'
      decoration = 'underline'
      break
    case 'sm':
      size = 12
      break
    default:
      transform = 'none'
  }
  return (
    <Text
      style={{
        fontFamily: face,
        fontSize: size,
        textTransform: transform,
        textDecorationLine: decoration,
        color: color,
        lineHeight: line,
        letterSpacing: letter,
        marginBottom: mb,
        ...style
      }}>
      {props.children}
    </Text>
  )
}

const clean = (content: string) => {
  const br = /<br>/gi
  return content.replaceAll(br, '\n')
}

export const StyledMarkdown: React.FC<{
  style?: any
}> = ({ style = mdStyles, ...props }) => {
  if (props.children?.valueOf()) {
    return <Markdown style={style}>{clean(props.children.toString())}</Markdown>
  } else {
    return <Markdown style={style}>{props.children}</Markdown>
  }
}
