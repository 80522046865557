import { Category } from 'types/category'

export interface ModalContents {
  title: string
  address: string
  images: string[]
  sections?: {
    id: number
    category: Category
    description: string
    authors: string
    citations: string
  }[]
}

export const makeModalContents = ({
  title = '',
  address = '',
  images = [],
  sections = []
}): ModalContents => ({
  title,
  address,
  images,
  sections
})
