import _ from 'lodash'
import { createContext, useEffect, useState } from 'react'
import { Region } from 'react-native-maps'
import { REGION_RH_INITAL } from './config'
import { Site } from 'types/site'
import { Language } from 'types/language'
import { MapLayer, makeMapLayer } from './types/map'
import { allSites as getAllSites, makeModalContentsFromSite } from 'providers/SitesProvider'
import { ModalContents, makeModalContents } from 'types/modal'
import { makePinLayerFromCategory } from 'providers/CategoriesProvider'
import { data } from 'db'
import { ENTER_TO_MENU } from 'config'
import { Category } from 'types/category'

export interface FinalNoticeState {
  actions: {
    openModalWith: (cd: Site) => void
    dismissModal: () => void
  }
  state: {
    intro: {
      visible: {
        get: boolean
        set: (b: boolean) => void
      }
    }

    modal: {
      contents: {
        get: ModalContents
        set: (mc: ModalContents) => void
      }
      loading: {
        get: string
        set: (s: string) => void
      }
      visible: {
        get: boolean
        set: (b: boolean) => void
      }
    }

    allSites: Site[],

    allModals: {
      get: { [s: string]: ModalContents }
      set: (m: { [s: string]: ModalContents }) => void
    }

    menu: {
      visible: {
        get: boolean
        set: (b: boolean) => void
      }
    }

    about: {
      visible: {
        get: boolean
        set: (b: boolean) => void
      }
    }

    appendix: {
      visible: {
        get: boolean
        set: (b: boolean) => void
      }
    }
    language: {
      get: Language
      set: (lang: Language) => void
    }

    currentMapRegion: {
      get: Region
      set: (r: Region) => void
    }

    layers: {
      // all: MapLayer[]
      active: string[]
    }

    baseLayer: {
      get: string
      set: (s: string) => void
      modal: {
        history: {
          get: string[]
          add: (s: string) => void
        }
        contents: {
          get: string
          set: (s: string) => void
        }
        visible: {
          get: boolean
          set: (b: boolean) => void
        }
      }
    }
  }
}

export const useFinalNotice = (): FinalNoticeState => {
  const [allSites, setAllSites] = useState<Site[]>(getAllSites())
  const [categories, setCategories] = useState<Category[]>()
  const [documents, setDocuments] = useState<Document[]>()
  const [sites, setSites] = useState<Site[]>()

  let [introVisible, setIntroVisible] = useState<boolean>(true)

  let [showModal, setShowModal] = useState<boolean>(false)
  let [currentMapRegion, setCurrentMapRegion] =
    useState<Region>(REGION_RH_INITAL)
  let [modalContents, setModalContents] = useState<ModalContents>(
    makeModalContents({})
  )

  let [allModals, setAllModals] = useState<{ [s: string]: ModalContents }>({})

  let openModalWith = (s: Site) => {
    setLoadingSite(s.slug)
    setModalContents(allModals[s.slug] || makeModalContentsFromSite(s))
    setShowModal(true)
  }
  let [loadingSite, setLoadingSite] = useState<string>()

  let [language, setLanguage] = useState<Language>(Language.EN)

  let [menuVisible, setMenuVisible] = useState<boolean>(ENTER_TO_MENU)
  let [aboutVisible, setAboutVisible] = useState<boolean>(false)
  let [appendixVisible, setAppendixVisible] = useState<boolean>(false)

  let [allLayers, setAllLayers] = useState<MapLayer[]>([])
  let [activeLayers, setActiveLayers] = useState<string[]>([])

  let [baseLayer, setBaseLayer] = useState<string>()
  let [baseLayerModalHistory, setBaseLayerModalHistory] = useState<string[]>([])
  const addBaseLayerToHistory = (s: string) => {
    let newArr = baseLayerModalHistory
    newArr.push(s)
    setBaseLayerModalHistory(newArr)
  }

  let [baseLayerModalContents, setBaseLayerModalContents] = useState<string>()
  let [baseLayerModalVisible, setBaseLayerModalVisible] =
    useState<boolean>(false)

  // Setup layer dataset
  useEffect(() => {
    // setAllLayers(
    //   data.categories
    //     .map((c) => {
    //       let newLayer = makeMapLayer(makePinLayerFromCategory(makeCategoryFromSc))
    //       return newLayer
    //     })
    //     .filter((l) => l.slug !== 'general-info')
    // )
    setActiveLayers(
      data.categories
        .map((c) => c.slug)
        .filter((s) =>
          [
            'take-action',
            'contaminated-sites',
            'living-histories',
            'historic-landmarks',
            'unmarked-histories',
            'climate-change'
          ].includes(s)
        )
    )
  }, [])

  useEffect(() => {
    if (baseLayer && !(baseLayer in baseLayerModalHistory)) {
      setBaseLayerModalContents(
        data.overlayLayers.find((ol) => ol.slug === baseLayer).description
      )
      setBaseLayerModalVisible(true)
    }
  }, [baseLayer])

  return {
    actions: {
      openModalWith,
      dismissModal: () => setShowModal(false)
    },

    state: {
      intro: {
        visible: {
          get: introVisible,
          set: setIntroVisible
        }
      },
      language: {
        get: language,
        set: setLanguage
      },
      modal: {
        contents: {
          get: modalContents,
          set: setModalContents
        },
        loading: {
          get: loadingSite,
          set: setLoadingSite
        },
        visible: {
          get: showModal,
          set: setShowModal
        }
      },

      allSites: allSites,

      allModals: {
        get: allModals,
        set: setAllModals
      },

      menu: {
        visible: {
          get: menuVisible,
          set: setMenuVisible
        }
      },

      about: {
        visible: {
          get: aboutVisible,
          set: setAboutVisible
        }
      },

      appendix: {
        visible: {
          get: appendixVisible,
          set: setAppendixVisible
        }
      },

      currentMapRegion: {
        get: currentMapRegion,
        set: setCurrentMapRegion
      },

      layers: {
        // all: allLayers,
        active: activeLayers
      },

      baseLayer: {
        get: baseLayer,
        set: setBaseLayer,
        modal: {
          history: {
            get: baseLayerModalHistory,
            add: addBaseLayerToHistory
          },
          contents: {
            get: baseLayerModalContents,
            set: setBaseLayerModalContents
          },
          visible: {
            get: baseLayerModalVisible,
            set: setBaseLayerModalVisible
          }
        }
      }
    }
  }
}


export const AppContext = createContext<FinalNoticeState | null>(null)
