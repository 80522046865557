import React, { useRef, useEffect, useState } from 'react'
import GoogleMapReact, {Marker} from 'google-map-react'
import mapStyle from 'styles/mapStyle.json'
import { DEFAULT_CENTER, BASE_LAYERS } from 'config'
import { Site } from 'types/site'
import MapMarker from './MapMarker'
import _ from 'lodash'
import { useMapOverlays } from 'providers/MapOverlayProvider'
import {useUserLocation} from 'providers/UserLocationProvider'
import {UserMarker} from 'components/UserMarker'

interface MapCanvasProps {
  sites: Site[]
  baseLayer: string | null
  onClickSite: (site: Site) => void
}

export const MapCanvas = ({
  sites,
  onClickSite,
  baseLayer
}: MapCanvasProps) => {
  const [google, setGoogle] = useState<any>(null)
  const mapOptions = (maps) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: mapStyle,
      gestureHandling: 'greedy',
      fullscreenControl: false,
      clickableIcons: false
    }
  }

  const mapRef = useRef(null)

  useMapOverlays(baseLayer, mapRef.current, google)

  const userLoc = useUserLocation()

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBjMwUV9S98I166HgXrhZnaJlfWByF0RUU' }}
      style={{
        width: '100vw',
        height: '100vh'
      }}
      defaultCenter={DEFAULT_CENTER.rh}
      zoom={14}
      options={mapOptions}
      ref={mapRef}
      onGoogleApiLoaded={(google: any) => setGoogle(google)}
      yesIWantToUseGoogleMapApiInternals>
      {sites.map((site) => (
        <MapMarker
          key={`${site.id}-${site.slug}`}
          lat={site.latitude}
          lng={site.longitude}
          site={site}
          onClick={onClickSite}
        />
      ))}
      {userLoc &&
        <UserMarker 
          lat={userLoc[0]}
          lng={userLoc[1]}
        />
      }
    </GoogleMapReact>
  )
}
