import React, { createContext } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavStackParams } from 'types/Navigation'
import { View } from 'react-native'
import { AppContext, useFinalNotice } from 'appState'
import { tailwind } from 'styles/tailwind'
import Intro from 'screens/Intro'
import Home from 'screens/Home'
import Map from 'screens/Map'
import MenuPage from 'screens/MenuPage'
import WelcomeModal from 'screens/modals/WelcomeModal'
import SiteModal from 'screens/modals/SiteModal'
import AppLoading from 'expo-app-loading'
import { ErrorBoundary } from 'components/ErrorBoundary'

import {
  useFonts,
  Muli_200ExtraLight,
  Muli_300Light,
  Muli_400Regular,
  Muli_600SemiBold,
  Muli_700Bold,
  Muli_800ExtraBold
} from '@expo-google-fonts/muli'

const Stack = createStackNavigator<NavStackParams>()

export default function App() {
  let app = useFinalNotice()
  let [fontsLoaded] = useFonts({
    Muli_200ExtraLight,
    Muli_300Light,
    Muli_400Regular,
    Muli_600SemiBold,
    Muli_700Bold,
    Muli_800ExtraBold
  })

  if (!fontsLoaded) {
    return <AppLoading />
  } else {
    return (
      <ErrorBoundary>
        <AppContext.Provider value={app}>
          <NavigationContainer>
            <Stack.Navigator
              initialRouteName="Intro"
              screenOptions={{
                headerShown: false
              }}>
              <Stack.Group>
                <Stack.Screen
                  name="Intro"
                  component={Intro}
                  options={{ title: 'FINAL NOTICE' }}
                />
                <Stack.Screen
                  name="Home"
                  component={Home}
                  options={{ title: 'FN | Home' }}
                />
                <Stack.Screen
                  name="Map"
                  component={Map}
                  options={{ title: 'FN | Map' }}
                />
                <Stack.Screen
                  name="MenuPage"
                  component={MenuPage}
                  options={{ title: 'FN | About' }}
                />
              </Stack.Group>
              <Stack.Group>
                <Stack.Screen
                  name="SiteModal"
                  component={SiteModal}
                  options={{
                    title: 'FN | Site',
                    presentation: 'transparentModal'
                  }}
                />
                <Stack.Screen
                  name="WelcomeModal"
                  component={WelcomeModal}
                  options={{
                    title: 'FN | Welcome',
                    presentation: 'transparentModal'
                  }}
                />
              </Stack.Group>
            </Stack.Navigator>
          </NavigationContainer>
        </AppContext.Provider>
      </ErrorBoundary>
    )
  }
}
