import { Language } from './language';

export interface Category {
  slug: string
  title: Record<Language, string>
  icon: string
  color: string
}

export const makeCategory = ({
  slug = '',
  title_en = '',
  title_sp = '',
  title = { [Language.EN]: title_en || '', [Language.ES]: title_sp || '' },
  icon = '',
  color = "#000"
}): Category => ({
  slug,
  title,
  icon,
  color
})
