import { Category, makeCategory } from 'types/category'
import { documentsBySite } from './DocumentsProvider'
import { data } from 'db'
import { PinLayer } from 'types/map'
import { Site, makeSite } from 'types/site'
import _ from 'lodash'
import { Language } from 'types/language'

export const categoriesBySite = (site: string): Category[] => {
  return [...new Set(documentsBySite(site).map(document => document.category))].map(slug => {
     //Super quick fix for badly formatted data in the spreadsheet
      if(slug == 'historic-landmark'){
        slug = 'historic-landmarks'
      }
      return makeCategory(data.categories.find(category => category.slug === slug))
  })
}

export const makeCategoryFromSlug = (s: string): Category => {
  return makeCategory({ ...data.categories.find((c) => c.slug === s) })
}

export const makeCategoriesForSite = (slug: string): Category[] => {
  const relatedDocuments = data.documents.filter((d) => d.site === slug)

  return Array.from(
    new Set(relatedDocuments.map((d) => makeCategoryFromSlug(d.category)))
  )
}

export const makePinLayerFromCategory = (c: Category): PinLayer => {
  let sites: Site[] = []
  data.documents.map((d) => {
    if (d.category === c.slug) {
      let s = data.sites.find((s) => s.slug === d.site)
      if (!_.isEmpty(s) && s.slug.length > 0 && !_.isEmpty(s.latitude)) {
        sites.push(
          makeSite({
            categories: makeCategoriesForSite(s.slug),
            ...s
          })
        )
      }
    }
  })
  return {
    slug: c.slug,
    title: c.title[Language.EN],
    sites: sites
  }
}
