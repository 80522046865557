import { makeSite, Site } from 'types/site'
import { ModalContents, makeModalContents } from 'types/modal'
import { data } from 'db'
import { categoriesBySite, makeCategoryFromSlug } from './CategoriesProvider'
import { documentsBySite } from './DocumentsProvider'
import _ from 'lodash'
import { imagesBySite } from './ImagesProvider'
import { audioBySite } from './AudioProvider'

export const allSites = () => {
  return data.sites.map(site => makeSite({
    ...site,
    categories: categoriesBySite(site.slug),
    documents: documentsBySite(site.slug),
    audio: audioBySite(site.slug),
    images: imagesBySite(site.slug)
  }))
}

export const filterSitesByCategories = (sites: Site[], categories: string[]) => {
  // console.log('filtering', sites.length, 'sites by: ', categories.join(', '))
  return sites.map(site => ({
    ...site,
    categories: site.categories.filter(category => categories.includes(category.slug)),
    documents: site.documents.filter(document => categories.includes(document.category)),
    // images: site.images.filter(
    //   image => image.categories.filter(
    //     category => categories.includes(category)
    //   ).length > 0)
  })).filter(site => site.categories.length > 0) as Site[]
}

export const makeModalContentsFromSite = (
  site: Site,
  categoryFilter?: string[]
): ModalContents => {
  const relatedDocuments = data.documents.filter(
    (d) =>
      d.site === site.slug &&
      (!categoryFilter ||
        (categoryFilter.length > 0 && categoryFilter.includes(d.category))) &&
      site.categories.map((c) => c.slug).includes(d.category)
  )

  // let allImages: string[] = []
  // relatedDocuments.map((d) => {
  //   allImages = allImages.concat(d.images)
  // })

  let mc = makeModalContents({
    title: site.title,
    address: site.address || '',
    images: site.images,
    sections: _.sortBy(
      relatedDocuments.map((d) => ({
        id: d.id,
        category: makeCategoryFromSlug(d.category),
        description: 'doc description',
        authors: 'doc authors',
        citations: 'doc citations'
        // description: d.description,
        // authors: d.authors,
        // citations: d.citations
      })),
      (section) => section.category
    )
  })

  return mc
}
