export const documentAudio = {
  1: require('./Akem_L.mp3'),
  2: require('./Arianna_V.mp3'),
  3: require('./Deandre_W.mp3'),
  4: require('./Devin_D.mp3'),
  5: require('./Iris_I.mp3'),
  6: require('./Isis_I.mp3'),
  7: require('./Jakai_L.mp3'),
  8: require('./Jazmine_N.mp3'),
  9: require('./Jholdy_C.mp3'),
  10: require('./Nevaeh_M.mp3'),
  11: require('./Tiffany_A.mp3'),
  12: require('./Valentina_T.mp3'),
  13: require('./Efrain_G.mp3'),
  14: require('./Magali.mp3'),
  15: require('./Marcelo_Luz_O.mp3'),
  16: require('./Maria_R.mp3'),
  17: require('./Titi_R.mp3')
}
