import React, { useContext } from 'react'
import { View, Image, TouchableOpacity, Dimensions } from 'react-native'
import { tailwind } from 'styles/tailwind'
import { StyledText } from 'components/StyledText'
import { IntroScreenProps } from 'types/Navigation'
import { AppContext } from 'appState'
import translations from 'content/translations'
import { Language } from 'types/language'

const Intro = ({ navigation }: IntroScreenProps) => {
  const app = useContext(AppContext)
  const language = app.state.language.get

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        alignContent: 'flex-start'
      }}>
      <View
        style={{
          ...tailwind('flex justify-center items-start')
        }}>
        <View
          style={tailwind(
            'flex self-end flex-shrink flex-row justify-end p-5'
          )}>
          <TouchableOpacity
            style={{
              ...(app.state.language.get === Language.EN
                ? tailwind('border-b-4 py-1 mr-4')
                : tailwind('border-b-4 border-white py-1 mr-4'))
            }}
            onPress={() => app.state.language.set(Language.EN)}>
            <StyledText el="p" style={tailwind('')}>
              {'English'}
            </StyledText>
          </TouchableOpacity>
          <TouchableOpacity
            style={
              app.state.language.get === Language.ES
                ? tailwind('border-b-4 py-1 mr-4')
                : tailwind('border-b-4 border-white py-1 mr-4')
            }
            onPress={() => app.state.language.set(Language.ES)}>
            <StyledText el="p" style={tailwind('')}>
              {'Español'}
            </StyledText>
          </TouchableOpacity>
        </View>
      </View>
      <View style={tailwind('pl-10')}>
        <Image
          source={require('../assets/logos/text-logo-flood-2.png')}
          style={{
            width: '75vw',
            height: 'calc(75vw * .4)',
            marginLeft: '-4vw'
          }}
          resizeMethod="auto"
        />
        <StyledText
          el="h4"
          style={{ ...tailwind('text-lg pt-6 pb-8 pr-16'), lineHeight: 20 }}>
          {translations['Intro Text'][language]}
        </StyledText>
        <TouchableOpacity
          style={tailwind('flex-shrink self-start')}
          onPress={() => navigation.push('Home')}>
          <View style={tailwind('rounded-full py-4 px-6 bg-black')}>
            <StyledText el="btn">
              {translations['Enter App'][language]}
            </StyledText>
          </View>
        </TouchableOpacity>
      </View>
      <View style={tailwind('self-end h-48')}>
        <Image
          source={require('../assets/logos/logo-outline-blk.png')}
          style={{
            width: '75vw',
            height: 'calc(75vw * .9)'
          }}
          resizeMethod="auto"
        />
      </View>
    </View>
  )
}

export default Intro
