import React, { useState, useEffect, useContext, useRef } from 'react'
import { View, Text, Button, TouchableOpacity } from 'react-native'
import { Audio, AVPlaybackStatus } from 'expo-av'
import { Audio as AudioType } from 'types/audio'
import { documentAudio } from 'assets/document_audio'
import { AUTOPLAY_LIVING_HISTORIES } from 'config'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import tailwind from 'tailwind-rn'
import { StyledText } from './StyledText'
import { AppContext } from 'appState'

export const AudioPlayer = ({ audio }: { audio: AudioType }) => {
  const app = useContext(AppContext)

  const sound = useRef(new Audio.Sound())

  const [playing, setPlaying] = useState<boolean>()

  const playSound = async () => {
    if (!sound.current._loaded)
      await sound.current.loadAsync(
        audio.uri ? { uri: audio.uri } : documentAudio[audio.id] // prefer URI
      )
    await sound.current.playAsync()
    setPlaying(true)
    sound.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)
  }

  const stopSound = () => {
    try {
      console.log('stopping audio')
      sound.current && sound.current.stopAsync()
      setPlaying(false)
    } catch (e) {
      console.warn(e)
    }
  }

  const onPlaybackStatusUpdate = (playbackStatus) => {
    if (!playbackStatus.isLoaded) {
      console.log('audio is unloaded')
      if (playbackStatus.error) {
        console.warn(
          `Encountered a fatal error during playback: ${playbackStatus.error}`
        )
      }
    } else {
      console.log('audio is loaded')

      if (playbackStatus.isPlaying) {
        console.log('audio is playing')
      } else {
        console.log('audio is paused')
      }

      if (playbackStatus.isBuffering) {
        console.log('audio is buffering')
      }

      if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
        console.log('audio is finished')
      }
    }
  }

  useEffect(() => {
    return sound.current
      ? async () => {
          try {
            await sound.current.unloadAsync()
            console.log('unloaded audio')
          } catch (e) {
            console.warn(e)
          }
        }
      : undefined
  }, [sound.current])

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          playing ? stopSound() : playSound()
        }}>
        <View style={tailwind('py-20 items-center justify-start text-center')}>
          <Icon
            style={tailwind('pb-4')}
            name={playing ? 'stop' : 'play'}
            size={40}
            color={'#000'}
          />
          <StyledText el="h3">
            {playing ? 'Now listening...' : 'Press to play'}
          </StyledText>
        </View>
      </TouchableOpacity>
    </View>
  )
}
