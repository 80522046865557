import React, { useContext, useEffect } from 'react'
import { ScrollView, View, Image, TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { tailwind } from 'styles/tailwind'
import { HomeScreenProps } from 'types/Navigation'
import { StyledText } from 'components/StyledText'
import { AppendixAccordian } from 'components/AppendixAccordian'
import { Language } from 'types/language'
import { AppContext } from 'appState'
import { data } from 'db'
import { FIRST_VISIT } from 'config'
import { allMenus } from 'providers/MenusProvider'

const Home = ({ navigation }: HomeScreenProps) => {
  const app = useContext(AppContext)
  const language = app.state.language.get
  const menus = allMenus()

  useEffect(() => {
    ;(async () => {
      try {
        const value = await AsyncStorage.getItem(FIRST_VISIT)
        if (!value) {
          navigation.push('WelcomeModal', { language: language })
          await AsyncStorage.setItem(FIRST_VISIT, 'true')
        }
      } catch (e) {}
    })()
  }, [])

  return (
    <View
      style={{
        ...tailwind('h-full bg-white'),
        position: 'relative',
        overflow: 'hidden'
      }}>
      <View
        style={{
          ...tailwind(
            'w-full h-12 flex flex-row justify-center items-center pl-8 my-4'
          )
        }}>
        <Image
          source={require('../assets/icons/ic_logo.png')}
          style={{
            height: 56,
            width: 56,
            marginRight: 10,
            marginTop: -5
          }}
        />
        <StyledText el="title-mini" style={tailwind('mr-2')}>
          {`FINAL
NOTICE`}
        </StyledText>
        <TouchableOpacity
          style={{
            ...(language === Language.EN
              ? tailwind('border-b-4 py-1 mr-4')
              : tailwind('border-b-4 border-white py-1 mr-4')),
            marginLeft: 'auto'
          }}
          onPress={() => app.state.language.set(Language.EN)}>
          <StyledText el="p" style={tailwind('')}>
            {'English'}
          </StyledText>
        </TouchableOpacity>
        <TouchableOpacity
          style={
            language === Language.ES
              ? tailwind('border-b-4 py-1 mr-4')
              : tailwind('border-b-4 border-white py-1 mr-4')
          }
          onPress={() => app.state.language.set(Language.ES)}>
          <StyledText el="p" style={tailwind('')}>
            {'Español'}
          </StyledText>
        </TouchableOpacity>
      </View>
      <View
        style={{
          ...tailwind('flex-grow relative pl-10 pr-4 justify-center')
        }}>
        {menus.map((menu) => (
          <TouchableOpacity
            key={menu.slug}
            style={tailwind('pr-2 py-2')}
            onPress={() =>
              navigation.navigate('MenuPage', {
                menu: menu,
                language: language
              })
            }>
            <StyledText el="h4">{menu.title[language] + '\u00a0›'}</StyledText>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          style={tailwind('pr-8 py-2')}
          onPress={() => navigation.navigate('Map')}>
          <StyledText el="h3">
            {data.translations['Explore Map'][language] + '\u00a0›'}
          </StyledText>
        </TouchableOpacity>
      </View>
      <ScrollView
        style={{
          width: '100vw',
          height: '40vh',
          borderTopWidth: 1,
          borderTopColor: 'lightgrey'
        }}>
        <AppendixAccordian language={language} />
      </ScrollView>
    </View>
  )
}

export default Home
