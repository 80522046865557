import appendix_content from './content/appendix'
import sites from './content/sites'
import documents from './content/documents'
import overlayLayers from './content/overlaylayers'
import categories from './content/categories'
import menus from './content/menus'
import translations from './content/translations'
import images from './content/images'
import audio from './content/audio'
import partners from './content/partners'

export const data = {
  categories,
  appendix: appendix_content,
  overlayLayers,
  documents,
  sites,
  menus,
  translations,
  images,
  audio,
  partners
}

export const imageBaseURL = "https://allofthedata.s3.us-west-2.amazonaws.com/finalnotice/images/"


