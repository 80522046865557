import { Language } from './language';

export interface Document {
  id: number
  site: string
  category: string
  description: Record<Language, string>
  citation: Record<Language, string>
  contributors: string
}

export const makeDocument = ({
  id,
  site,
  category,
  description_en,
  description_sp,
  citation_en,
  citation_sp,
  description = { [Language.EN]: description_en || '', [Language.ES]: description_sp || ''},
  citation = { [Language.EN]: citation_en || '', [Language.ES]: citation_sp || ''},
  contributors = ''
}): Document => ({
  id,
  site,
  category,
  description,
  citation,
  contributors
})
