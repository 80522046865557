import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  ScrollView,
  View,
  TouchableOpacity,
  Dimensions,
  Modal,
  Image
} from 'react-native'
import { getColor } from 'styles/tailwind'
import tailwind from 'tailwind-rn'
import { StyledText, StyledMarkdown } from 'components/StyledText'
import { WelcomeModalScreenProps } from 'types/Navigation'
import translations from 'content/translations'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

const WelcomeModal = ({ navigation, route }: WelcomeModalScreenProps) => {
  const language = route.params?.language

  return (
    <Modal transparent={true}>
      <TouchableOpacity
        style={{
          height: '100vh',
          width: '100vw',
          backgroundColor: 'rgba(0,0,0,0.25)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onPress={() => navigation.goBack()}>
        <TouchableOpacity
          style={[
            tailwind('flex flex-shrink bg-white m-8 p-8'),
            {
              shadowOffset: {
                height: 25,
                width: 0
              },
              shadowOpacity: 0.25,
              shadowRadius: 50
            }
          ]}
          onPress={() => {}}>
          <StyledMarkdown>{translations.Disclaimer[language]}</StyledMarkdown>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  )
}

export default WelcomeModal
