import React from 'react'
import { View, Text, Image } from 'react-native'
import { Category } from 'types/category'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import tailwind from 'tailwind-rn'
import _ from 'lodash'

const IconEl = (name: string, color: string, size: number = 16) => {
  if (name === 'take-action') {
    if (color === '#fff' || color === 'white') {
      return (
        <Image
          source={require('../assets/icons/ic_take-action-white.png')}
          style={{ height: size, width: size }}
        />
      )
    } else {
      return (
        <Image
          source={require('../assets/icons/ic_take-action-blk.png')}
          style={{ height: size, width: size }}
        />
      )
    }
  } else {
    return <Icon name={name || 'map-marker'} size={size} color={color} />
  }
}

interface CategoryIconProps {
  categories: Category[]
  variant?: string
}

export const CategoryIcon = ({ categories, variant }: CategoryIconProps) => {
  let bgColor = '#000'
  let iconName = 'map-pin'

  if (categories.length > 0) {
    bgColor = categories[0].color
    iconName = categories[0].icon
  }

  if (variant === 'map') {
    return (
      <View
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: 30,
          width: 30,
          borderRadius: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: bgColor
        }}>
        {categories.length === 1 && IconEl(iconName, '#fff')}
        {categories.length >= 2 && (
          <View
            style={{
              height: 30,
              width: 30,
              position: 'absolute',
              left: 15,
              top: 0,
              backgroundColor: categories[1].color
            }}></View>
        )}
        {categories.length >= 3 && (
          <View
            style={{
              height: 30,
              width: 10,
              position: 'absolute',
              left: 10,
              top: 0,
              backgroundColor: categories[2].color
            }}></View>
        )}
      </View>
    )
  } else if (variant === 'plain') {
    return (
      <View
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: 30,
          width: 30,
          borderRadius: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {IconEl(iconName, bgColor || categories[0].color, 16)}
      </View>
    )
  }
}
