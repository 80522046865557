import translations from 'content/translations'
import React, { useContext } from 'react'
import { View, Text, SafeAreaView, TouchableOpacity, Image } from 'react-native'
import tailwind from 'tailwind-rn'
import { StyledText } from './StyledText'

export const TitleBar = ({ language, callback }) => {
  return (
    <SafeAreaView
      style={tailwind(
        'absolute top-0 left-0 right-0 w-full text-black h-16 flex flex-row justify-start items-center'
      )}>
      <TouchableOpacity
        style={tailwind('flex-shrink pl-5 pr-4')}
        onPress={callback}>
        <Image
          source={require('../assets/icons/ic_logo.png')}
          style={{
            height: 32,
            width: 32
          }}
        />
        <StyledText el="subtitle">{translations.Menu[language]}</StyledText>
      </TouchableOpacity>
    </SafeAreaView>
  )
}
