import React from 'react'
import { View, ImageBackground, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import Swiper from 'react-native-web-swiper'
import tailwind from 'tailwind-rn'
import { Image as ImageType } from 'types/image'

const ImageSlider = ({
  images,
  handleZoom
}: {
  images: ImageType[]
  handleZoom: () => void
}) => {
  return (
    <View style={tailwind('w-full h-64 z-10')}>
      <Swiper
        from={0}
        loop={false}
        timeout={0}
        springConfig={{ friction: 100 }}
        controlsEnabled={false}>
        {images.map((image) => (
          <ImageBackground
            key={image.id}
            style={tailwind('h-full')}
            source={{ uri: image.src }}
          />
        ))}
      </Swiper>
      <Icon
        style={{ ...tailwind('absolute p-4'), top: 0, right: 0 }}
        name="arrow-expand"
        color="#000"
        size={20}
        onPress={handleZoom}
      />
    </View>
  )
}

export default ImageSlider
