export const REGION_RH_INITAL = {
  latitude: 40.65854229100531,
  latitudeDelta: 0.040350462142264126,
  longitude: -74.0092264117104,
  longitudeDelta: 0.02458043396472931
}

export const REGION_NB_INITAL ={
  latitude: 40.702743902725416,
  longitude: -73.95090815560712
}

export const DEFAULT_CENTER = {
  rh: [
    REGION_RH_INITAL.latitude,
    REGION_RH_INITAL.longitude
  ],
  ep: []
}

export const ENTER_TO_MENU = true

export const AUTOPLAY_LIVING_HISTORIES = true

export const CATEGORIES = [
  'living-histories',
  'unmarked-histories',
  'historic-landmarks',
  'contaminated-sites',
  'climate-change',
  'take-action'
]

export const BASE_LAYERS = [
  'sandy',
  'tide_2050',
  'tide_2100',
  'floodplain_2050',
  'floodplain_2080',
  'heat_islands'
]

export const FIRST_VISIT = 'FN_DISCLAIMER_SHOWN'
