import { Image, makeImage } from 'types/image'
import { data, imageBaseURL } from 'db'

export const imagesBySite = (site: string) => {
  const images = data.images
    .filter(image => image.site === site)
    .map(image => makeImage({
      id: image.id,
      site: image.site,
      categories: image.categories,
      citation_en: image.citation_en,
      citation_sp: image.citation_sp,
      filename: image.src,
      src: imageBaseURL + image.src
    }))
    return images
}
